import { toast } from 'react-toastify';

const Hooks = () => {
  const token = localStorage.getItem('user-token');
  let userVerified;
  let isUserTypeExists;
  if (localStorage.getItem('userdata')) {
    userVerified = JSON.parse(localStorage.getItem('userdata'))?.verified;
    isUserTypeExists = JSON.parse(localStorage.getItem('userdata'))?.userType;
  }

  const IsUserLoggedIn = () => {
    if (token && isUserTypeExists) {
      return true;
    } else {
      return false;
    }
  };

  const Logout = navigate => {
    localStorage.clear();
    sessionStorage.removeItem('userType');
    sessionStorage.removeItem('5min-interavl-permiumpopup-shown');
    toast.success('Logout successful!');
    navigate('/');
  };

  return {
    IsUserLoggedIn,
    Logout
  };
};

export default Hooks;
