import { useState, useEffect } from 'react';

const useSessionStorage = (key, initialValue) => {
  const [value, setValue] = useState(() => {
    const storedValue = sessionStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : initialValue;
  });

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
    window.dispatchEvent(new CustomEvent('sessionStorageChange', { detail: { key, value } }));
  }, [key, value]);

  useEffect(() => {
    const handleStorageChange = event => {
      if (event.key === key) {
        setValue(event.newValue ? JSON.parse(event.newValue) : initialValue);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key, initialValue]);

  useEffect(() => {
    const handleInternalChange = event => {
      if (event.detail.key === key) {
        setValue(event.detail.value);
      }
    };

    window.addEventListener('sessionStorageChange', handleInternalChange);

    return () => {
      window.removeEventListener('sessionStorageChange', handleInternalChange);
    };
  }, [key]);

  return [value, setValue];
};

export default useSessionStorage;
