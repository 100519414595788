import React, { useRef, useState, useEffect } from 'react';
import ScopeIcon from '../../assets/Images/Scope';
import { Link, useLocation, useParams } from 'react-router-dom';
import SearchButtonIcon from '../../assets/Images/Search';
import CrossBorderIcon from '../../assets/Images/CrossBorder';
// import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import useDebounce from '../../hooks/useDebounce';
import Axios from '../../axios/Axios';
import { toast } from 'react-toastify';
import DistanceIcon from '../../assets/Images/Distance';
import CountIcon from '../../assets/Images/Count';
import PriceIcon from '../../assets/Images/Price';
import FavouriteIcon from '../../assets/Images/FavouriteIcon';
import RatingIcon from '../../assets/Images/Rating';
import LocationIcon from '../../assets/Images/Location';
import TimerIcon from '../../assets/Images/Timer';
import EmptyDataImage from '../../assets/Images/EmptyData';
import { PaginationControl } from 'react-bootstrap-pagination-control';
import MobSearchIcon from '../../assets/Images/MobSearch';
import axios from 'axios';
import MultiRangeSlider from 'multi-range-slider-react';
import FilterIcon from '../../assets/Images/filterIcon';
import AddressInput from '../Address/address';
import { useMemo } from 'react';
import { Tooltip } from 'react-bootstrap';
import ImageDisplay from '../Social App/ImageDisplay';
import useSessionStorage from '../../hooks/useSessionStorage';

const AllProductsDashboard = props => {
  const Location = useLocation();
  const [type, setType] = useState('Grams');
  const [customType, setCustomType] = useState('CustomSelect');
  const [categoryFilter, setcategoryFilter] = useState([]);
  const [data, setData] = useState([]);
  const routeParams = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchedTerm = useDebounce(searchTerm);
  const [page, setPage] = useState(1);
  const inputRef1 = useRef();
  const [quantityFilter, setQuantityFilter] = useState('');
  const [userType, setUserType] = useState(localStorage.getItem('userType') || 'retailer');
  const [isLoading, setIsLoading] = useState(false);
  const [strainSearchCount, setStrainSearchCount] = useSessionStorage('strain-search-count', 0);

  const [filter, setFilter] = useState({
    radius: 0,
    area: '',
    quantity: ''
  });

  const allOptions = [
    {
      id: 1,
      value: 'all',
      label: 'All',
      userType: 'retailer',
      active: true
    },
    {
      id: 2,
      value: 'seedStore',
      label: 'Seed Bank',
      userType: 'retailer',
      active: true
    },
    {
      id: 3,
      value: 'dispensary',
      label: 'Dispensary',
      userType: 'retailer',
      active: true
    },
    {
      id: 4,
      value: 'cannabisLounge',
      label: 'Cannabis Lounge',
      userType: 'retailer',
      active: true
    },
    {
      id: 5,
      value: 'headShop',
      label: 'Head Shop',
      userType: 'retailer',
      active: true
    },
    {
      id: 6,
      value: 'growDepot',
      label: 'Grow Depot',
      userType: 'retailer',
      active: true
    },
    {
      id: 1,
      value: 'all',
      label: 'All',
      userType: 'consumer',
      active: true
    },
    {
      id: 2,
      value: 'Sativa',
      label: 'Sativa',
      userType: 'consumer',
      active: true
    },
    {
      id: 3,
      value: 'Indica',
      label: 'Indica',
      userType: 'consumer',
      active: true
    },
    {
      id: 4,
      value: 'Hybrid',
      label: 'Hybrid',
      userType: 'consumer',
      active: true
    },
    {
      id: 5,
      value: 'CBD',
      label: 'CBD',
      userType: 'consumer',
      active: true
    }
  ];

  const [options, setOptions] = useState(allOptions);

  const [groupData, setGroupData] = useState({});
  const GetAllProduct = async GetAllProductUrl => {
    setIsLoading(true);
    try {
      const currentUser = localStorage.getItem('userdata');
      let data = JSON.parse(currentUser);
      const userId = data?._id;
      const fetchData = await DashboardAxios.get(GetAllProductUrl.concat(`&ignoreUserId=${userId}`));
      setData(fetchData.data);
      const groupByCategory = fetchData.data.result.reduce((group, product) => {
        const { userId } = product;
        if (!userId) return group;
        const { storeName, email } = userId;
        const key = storeName || email;
        if (!group[key]) {
          group[key] = [];
        }
        group[key].push(product);
        return group;
      }, {});

      setGroupData(groupByCategory);
    } catch (error) {
      toast.error(error.response?.data?.message);
      console.log(error);
    }
    setIsLoading(false);
  };
  const GetAllProductUseEffect = async GetAllProductUrl => {
    setIsLoading(true);
    try {
      const currentUser = localStorage.getItem('userdata');
      let data = JSON.parse(currentUser);
      const userId = data?._id;
      const fetchData = await Axios.get(GetAllProductUrl.concat(`&ignoreUserId=${userId}`));
      setData(fetchData.data);
      const groupByCategory = fetchData.data.result.reduce((group, product) => {
        const { storeName } = product.userId;
        group[storeName] = group[storeName] ?? [];
        group[storeName].push(product);
        return group;
      }, {});
      setGroupData(groupByCategory);
    } catch (error) {
      toast.error(error.response?.data?.message);
      console.log(error);
    }
    setIsLoading(false);
  };

  const handleChange = event => {
    setType(event.target.value);
    if (event.target.value === 'All') {
      setFilter(prevState => ({
        ...prevState,
        quantity: ''
      }));
    }
  };

  useEffect(() => {
    localStorage.removeItem('signupData');
    localStorage.removeItem('budSeedFilter');
  }, []);

  useEffect(() => {
    if (searchTerm) {
      setStrainSearchCount(prevCount => prevCount + 1);
    }
    setIsLoading(true);
    const savedFilter = JSON.parse(localStorage.getItem('homeFilter'));
    if (savedFilter) {
      const tempRadius = savedFilter.radius.split('-');
      setMinMax({
        minValue: tempRadius[0],
        maxValue: tempRadius[1]
      });
      setFilter(savedFilter);
      const currentUser = localStorage.getItem('userdata');
      let data = JSON.parse(currentUser);
      let GetAllProductUrl = `${process.env.REACT_APP_API_URI}users/getDataByRadius?page=${page}&userType=${userType}${
        savedFilter.radius !== 0 ? `&radius=${savedFilter.radius}` : ''
      }${savedFilter.quantity && `&quantity=${savedFilter.quantity}`}${
        savedFilter.area
          ? `&address=${savedFilter.area}`
          : `&latlang=${data?.location?.coordinates[0]},${data?.location?.coordinates[1]}`
      }&category=${categoryFilter?.join(',')}`;
      GetAllProduct(GetAllProductUrl);
    } else {
      const currentUser = localStorage.getItem('userdata');
      let data = JSON.parse(currentUser);
      let GetAllProductUrl = `${process.env.REACT_APP_API_URI}users/${
        routeParams.radius
          ? `getDataByRadius?${routeParams.radius}&page=${page}&userType=${userType}&name=${searchTerm}&`
          : `getAllStrainData/?page=${page}&name=${searchTerm}&`
      }latlang=${data?.location?.coordinates[0]},${
        data?.location?.coordinates[1]
      }&userType=${userType}&category=${categoryFilter?.join(',')}`;
      GetAllProduct(GetAllProductUrl).finally(() => {
        setIsLoading(false);
      });
    }
  }, [debouncedSearchedTerm, categoryFilter]);

  const userTyperHandler = async type => {
    setIsLoading(true);
    setSearchTerm('');
    setOptions(allOptions);
    setUserType(type);
    localStorage.setItem('userType', type);
    localStorage.removeItem('homeFilter');
    setcategoryFilter([]);
    const currentUser = localStorage.getItem('userdata');
    let data = JSON.parse(currentUser);
    let GetAllProductUrl = `${process.env.REACT_APP_API_URI}users/${
      routeParams.radius
        ? `getDataByRadius?${routeParams.radius}&page=${page}&userType=${userType}&name=${searchTerm}&`
        : `getAllStrainData/?page=${page}&name=${searchTerm}&`
    }latlang=${data?.location?.coordinates[0]},${data?.location?.coordinates[1]}&userType=${type}&category=`;
    await GetAllProduct(GetAllProductUrl);
    setIsLoading(false);
  };

  const formHandler = e => {
    const { name, value } = e.target;
    setFilter(prevState => ({
      ...prevState,
      [name]: value
    }));
    localStorage.setItem('homeFilter', JSON.stringify(filter));
  };

  const inputRef = useRef();
  // // console.log(categoryFilter);
  // if (categoryFilter.length === 1 && categoryFilter[0] === "dispensary") {
  //   console.log('Only "dispensary" filter is applied.');
  // } else {
  //   console.log("Other filters are also applied.");
  // }
  useEffect(() => {
    const hasDispensary = categoryFilter.includes('dispensary');
    const hasSeedStore = categoryFilter.includes('seedStore') || categoryFilter.includes('seedbank');
    const hasBoth = hasDispensary && hasSeedStore;
    // console.log(hasBoth);
    // console.log(quantityFilter);

    if (hasDispensary) {
      setQuantityFilter('dispensary');
      setType('grams');
    }
    if (hasSeedStore) {
      setQuantityFilter('seedStore');
      setType('Seeds');
    }
    if (hasBoth || categoryFilter === '') {
      setQuantityFilter('all');
      setType('Seeds');
    }

    //  if categoryFilter has headShop of cannabisLounge then
    if (
      categoryFilter.includes('headShop') ||
      categoryFilter.includes('cannabisLounge') ||
      categoryFilter.includes('growDepot')
    ) {
      setQuantityFilter('none');
    }
    if (categoryFilter.length === 0 || categoryFilter.includes('')) {
      setQuantityFilter('');
    }
  }, [categoryFilter]);

  // useEffect(() => {
  //   setIsLoading(true);

  //   const savedFilter = JSON.parse(localStorage.getItem("homeFilter"));

  //   if (savedFilter) {
  //     setFilter(savedFilter);
  //     const currentUser = localStorage.getItem("userdata");
  //     let data = JSON.parse(currentUser);
  //     let GetAllProductUrl = `${process.env.REACT_APP_API_URI
  //       }users/getDataByRadius?page=${page}&userType=${userType}${savedFilter.radius > 0 ? `&radius=${savedFilter.radius}` : ""
  //       }${`&quantity=${savedFilter.quantity}`}${savedFilter.area
  //         ? `&address=${savedFilter.area}`
  //         : `&latlang=${data?.location?.coordinates[0]},${data?.location?.coordinates[1]}`
  //       }&category=${categoryFilter?.join(",")}`;
  //     GetAllProduct(GetAllProductUrl);
  //   } else {
  //     const currentUser = localStorage.getItem("userdata");
  //     let data = JSON.parse(currentUser);
  //     setcurrentuserData(data);
  //     let GetAllProductUrl = `${process.env.REACT_APP_API_URI}users/${routeParams.radius
  //       ? `getDataByRadius?${routeParams.radius}&page=1&`
  //       : `getAllStrainData/?page=1&`
  //       }latlang=${data?.location?.coordinates[0]},${data?.location?.coordinates[1]
  //       }&userType=${userType}&category=${categoryFilter?.join(",")}`;
  //     GetAllProductUseEffect(GetAllProductUrl);
  //   }
  //   setIsLoading(true);

  // }, []);

  const submitHandler = event => {
    event.preventDefault();
    const { name, value } = event.target;
    if (name === 'quantity') {
      setFilter(prevState => ({
        ...prevState,
        quantity: value
      }));
      const currentUser = localStorage.getItem('userdata');
      let data = JSON.parse(currentUser);
      let GetAllProductUrl = `${
        process.env.REACT_APP_API_URI
      }users/getDataByRadius?page=${page}&userType=${userType}&radius=${minMax.minValue}-${minMax.maxValue}${
        value && `&quantity=${value}`
      }${
        filter.area
          ? `&address=${filter.area}`
          : `&latlang=${data?.location?.coordinates[0]},${data?.location?.coordinates[1]}`
      }&category=${categoryFilter?.join(',')}`;
      GetAllProduct(GetAllProductUrl);
    } else {
      const currentUser = localStorage.getItem('userdata');
      let data = JSON.parse(currentUser);
      let GetAllProductUrl = `${
        process.env.REACT_APP_API_URI
      }users/getDataByRadius?page=${page}&userType=${userType}&radius=${minMax.minValue}-${minMax.maxValue}${
        filter.quantity && `&quantity=${filter.quantity}`
      }${
        filter.area
          ? `&address=${filter.area}`
          : `&latlang=${data?.location?.coordinates[0]},${data?.location?.coordinates[1]}`
      }&category=${categoryFilter?.join(',')}`;
      GetAllProduct(GetAllProductUrl);
    }
    const filterTemp = {
      radius: `${minMax.minValue}-${minMax.maxValue}`,
      quantity: filter.quantity,
      area: filter.area
    };
    localStorage.setItem('homeFilter', JSON.stringify(filterTemp));
  };

  const pageHandler = page => {
    setPage(page);
    const currentUser = localStorage.getItem('userdata');
    const filter = localStorage.getItem('homeFilter');
    const parsFilter = JSON.parse(filter);
    let data = JSON.parse(currentUser);
    let GetAllProductUrl = `${process.env.REACT_APP_API_URI}users/${
      parsFilter?.radius
        ? `getDataByRadius?radius=${parsFilter?.radius}&page=${page}&userType=${userType}&`
        : `getAllStrainData/?page=${page}&`
    }latlang=${data?.location?.coordinates[0]},${
      data?.location?.coordinates[1]
    }&userType=${userType}&category=${categoryFilter?.join(',')}`;
    GetAllProduct(GetAllProductUrl);
  };

  const favouriteHandler = (userId, prodId, categry) => {
    const currentUser = localStorage.getItem('userdata');
    let data = JSON.parse(currentUser);
    const markdata = {
      userId: userId,
      pId: prodId,
      category: categry,
      latlang: `${data?.location?.coordinates[0]},${data?.location?.coordinates[1]}`
    };
    axios
      .post(`${process.env.REACT_APP_API_URI}users/markFavourite`, markdata)
      .then(response => {
        setData(prevState => ({
          ...prevState,
          result: prevState.result.map(product => {
            if (product?._id === response?.data?.findproduct?._id) {
              return response?.data?.findproduct;
            } else {
              return product;
            }
          })
        }));
        toast.success(response.data?.messgae);
      })
      .catch(error => {
        toast.error(error?.response?.data?.message);
        console.log(error);
      });
  };

  const handlePlaceChanged = () => {
    const [place] = inputRef1.current.getPlaces();
    if (place) {
      setFilter(prevState => ({
        ...prevState,
        area: place.formatted_address
      }));
    }
  };

  useEffect(() => {
    if (searchTerm !== '') {
      localStorage.removeItem('homeFilter');
    }
  }, [searchTerm]);

  // useEffect(() => {
  //   handleCheckboxChange({ target: { value: "all", checked: true } });
  // }, []);
  const handleCheckboxChange = event => {
    setIsLoading(true);
    setSearchTerm('');

    localStorage.removeItem('homeFilter');

    const { value, checked } = event.target;
    if (value === 'all') {
      setcategoryFilter(checked ? [''] : []);
      const updatedOptions = options.map(option => ({
        ...option,
        checked: option.value === 'all' ? checked : false
      }));
      setOptions(updatedOptions);
    } else {
      const updatedOptions = options.map(option => (option.value === value ? { ...option, checked } : option));
      setOptions(updatedOptions);

      if (checked) {
        setcategoryFilter(prevArray => [...prevArray, value]);
      } else {
        setcategoryFilter(prevStrings => prevStrings.filter(string => string !== value));
      }
      if (categoryFilter.includes('')) {
        setOptions(prevOptions =>
          prevOptions.map(option => (option.value === 'all' ? { ...option, checked: false } : option))
        );
        setcategoryFilter(prevStrings => prevStrings.filter(string => string !== ''));
      }
    }
    setIsLoading(false);
  };

  // useEffect(() => {
  //   async function test() {
  //     const savedFilter = JSON.parse(localStorage.getItem("homeFilter"));

  //     if (savedFilter) {
  //       setFilter(savedFilter);
  //       const currentUser = localStorage.getItem("userdata");
  //       let data = JSON.parse(currentUser);
  //       let GetAllProductUrl = `${
  //         process.env.REACT_APP_API_URI
  //       }users/getDataByRadius?page=${page}&userType=${userType}${
  //         savedFilter.radius > 0 ? `&radius=${savedFilter.radius}` : ""
  //       }${`&quantity=${savedFilter.quantity}`}${
  //         savedFilter.area
  //           ? `&address=${savedFilter.area}`
  //           : `&latlang=${data?.location?.coordinates[0]},${data?.location?.coordinates[1]}`
  //       }&category=${categoryFilter?.join(",")}`;
  //       await GetAllProduct(GetAllProductUrl);
  //     } else {
  //       const currentUser = localStorage.getItem("userdata");
  //       let data = JSON.parse(currentUser);
  //       let GetAllProductUrl = `${process.env.REACT_APP_API_URI}users/${
  //         routeParams.radius
  //           ? `getDataByRadius?${routeParams.radius}&page=1&`
  //           : `getAllStrainData/?page=1&`
  //       }latlang=${data?.location?.coordinates[0]},${
  //         data?.location?.coordinates[1]
  //       }&userType=${userType}&category=${categoryFilter?.join(",")}`;
  //       await GetAllProduct(GetAllProductUrl);
  //     }
  //   }
  //   test().then(() => {
  //     setIsLoading(false);
  //   });
  // }, [categoryFilter]);

  const clearFilterHandler = e => {
    e.preventDefault();
    localStorage.removeItem('homeFilter');
    setFilter({
      radius: 0,
      area: '',
      quantity: ''
    });
    setMinMax({
      minValue: 0,
      maxValue: 250
    });
    const currentUser = localStorage.getItem('userdata');
    let data = JSON.parse(currentUser);
    let GetAllProductUrl = `${
      process.env.REACT_APP_API_URI
    }users/getAllStrainData/?page=${page}&category=${categoryFilter?.join(
      ','
    )}&userType=${userType}&name=${searchTerm}&latlang=${data?.location?.coordinates[0]},${
      data?.location?.coordinates[1]
    }`;
    GetAllProduct(GetAllProductUrl);
  };

  const DashboardAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API_URI}/api/v1/`
  });

  DashboardAxios.interceptors.request.use(request => {
    document.querySelector('.loader-main').style.display = 'block';
    return request;
  });

  DashboardAxios.interceptors.response.use(
    response => {
      document.querySelector('.loader-main').style.display = 'none';
      return response;
    },
    error => {
      document.querySelector('.loader-main').style.display = 'none';
      throw error;
    }
  );

  const [minMax, setMinMax] = useState(() => {
    const savedFilter = JSON.parse(localStorage.getItem('homeFilter'));
    if (savedFilter) {
      const tempRadius = savedFilter.radius.split('-');
      return {
        minValue: parseInt(tempRadius[0], 10),
        maxValue: parseInt(tempRadius[1], 10)
      };
    }
    return {
      minValue: 0,
      maxValue: 250
    };
  });

  const handleInput = useMemo(() => {
    return e => {
      setMinMax({
        minValue: e.minValue,
        maxValue: e.maxValue
      });
      setFilter(prevState => ({
        ...prevState,
        radius: `${e.minValue}-${e.maxValue}`
      }));
    };
  }, [setMinMax, setFilter]);
  const quantity = ['1-7', '7-14', '14-30'];

  return (
    <>
      <div className='all-product-section '>
        <div className='allproduct-mob d-block'>
          <div className='container mx-auto'>
            <div className='d-flex flex-sm-row flex-column align-items-sm-center justify-content-between gap-4 ps-12 pe-12'>
              <div className='d-flex align-items-center align-items-sm-start align-items-md-center w-100-sm-md flex-md-row flex-sm-column gap-sm-4 gap-2 justify-content-between'>
                <h2 className='allproduct-heading'>All Products</h2>
                <div
                  className='btn-group btn-group-toggle height-56px btn-group-dash d-flex d-sm-none d-md-flex'
                  data-toggle='buttons'
                >
                  <label
                    className={`btn font-14 bg-grey d-flex align-items-center px-sm-3 px-2 ${
                      userType === 'retailer' ? 'active' : ''
                    }`}
                  >
                    <input
                      type='radio'
                      name='options'
                      id='Grams'
                      autoComplete='off'
                      readOnly
                      checked={userType === 'retailer'}
                      value='retailer'
                      onChange={e => userTyperHandler(e.target.value)}
                    />
                    <span>Retailer</span>
                  </label>
                  <label
                    className={`btn font-14 bg-grey d-flex align-items-center px-sm-3 px-2 ${
                      userType === 'consumer' ? 'active' : ''
                    }`}
                  >
                    <input
                      type='radio'
                      name='options'
                      id='Seeds'
                      value='consumer'
                      autoComplete='off'
                      checked={userType === 'consumer'}
                      onChange={e => userTyperHandler(e.target.value)}
                    />
                    <span>Consumer</span>
                  </label>
                </div>
                <div className='d-sm-flex d-md-none d-none align-items-center justify-content-between gap-sm-4 gap-3 w-100'>
                  <div className='btn-group btn-group-toggle height-56px btn-group-dash' data-toggle='buttons'>
                    <label
                      className={`btn font-14 bg-grey d-flex align-items-center px-sm-3 px-2 ${
                        userType === 'retailer' ? 'active' : ''
                      }`}
                    >
                      <input
                        type='radio'
                        name='options'
                        id='Grams'
                        autoComplete='off'
                        readOnly
                        checked={userType === 'retailer'}
                        value='retailer'
                        onChange={e => userTyperHandler(e.target.value)}
                      />
                      <span>Retailer</span>
                    </label>
                    <label
                      className={`btn font-14 bg-grey d-flex align-items-center px-sm-3 px-2 ${
                        userType === 'consumer' ? 'active' : ''
                      }`}
                    >
                      <input
                        type='radio'
                        name='options'
                        id='Seeds'
                        value='consumer'
                        autoComplete='off'
                        checked={userType === 'consumer'}
                        onChange={e => userTyperHandler(e.target.value)}
                      />
                      <span>Consumer</span>
                    </label>
                  </div>
                  <div className='d-flex align-items-center gap-sm-4 gap-3'>
                    <div className='search-product d-sm-none d-flex'>
                      <input
                        placeholder='Search Product'
                        type='text'
                        className='border-0 outline-0 bg-transparent w-100'
                        onChange={e => setSearchTerm(e.target.value)}
                        value={searchTerm}
                      />
                      <span className='icon-green-bg'>
                        <MobSearchIcon />
                      </span>
                    </div>

                    <div className='d-flex align-items-center w-max-content gap-sm-4 gap-3'>
                      {!Location.pathname.includes('map') ? (
                        <a
                          href={`${Location.pathname}/map`}
                          className='text-white view-map-btn p-2 d-sm-flex d-none align-items-center gap-3'
                        >
                          <span className='d-md-block d-none ps-2'>View Map</span>
                          <span className='view-map-btn-scope d-flex align-items-center  justify-content-center '>
                            <ScopeIcon />
                          </span>
                        </a>
                      ) : (
                        <Link className='text-white view-map-btn p-2 d-sm-flex d-none align-items-center gap-3'>
                          <span className='d-md-block d-none ps-2'>View Map</span>
                          <span className='view-map-btn-scope d-flex align-items-center justify-content-center '>
                            <ScopeIcon />
                          </span>
                        </Link>
                      )}

                      <div className='d-flex align-items-center w-max-content'>
                        <button
                          className='border-0 outline-0 bg-transparent p-0 height-56'
                          data-bs-toggle='modal'
                          data-bs-target='#deactivatemodal'
                        >
                          <FilterIcon />
                        </button>
                      </div>
                      {!Location.pathname.includes('map') ? (
                        <a
                          href={`${Location.pathname}/map`}
                          className='text-white view-map-btn p-2 d-flex d-sm-none align-items-center gap-3 height-56 rounded-3'
                        >
                          <span className='view-map-btn-scope d-flex align-items-center justify-content-center h-100 w-max-content p-1 rounded-3'>
                            <ScopeIcon />
                          </span>
                        </a>
                      ) : (
                        <Link className='text-white view-map-btn p-2 d-flex d-sm-none align-items-center gap-3 height-56 rounded-3'>
                          <span className='view-map-btn-scope d-flex align-items-center justify-content-center h-100 w-max-content p-1 rounded-3'>
                            <ScopeIcon />
                          </span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex d-sm-none d-md-flex align-items-center gap-sm-4 gap-3'>
                <div className='search-product d-sm-none d-flex'>
                  <input
                    placeholder='Search Product'
                    type='text'
                    className='border-0 outline-0 bg-transparent w-100'
                    onChange={e => setSearchTerm(e.target.value)}
                    value={searchTerm}
                  />
                  <span className='icon-green-bg'>
                    <MobSearchIcon />
                  </span>
                </div>

                <div className='d-flex align-items-center w-max-content gap-sm-4 gap-3'>
                  {!Location.pathname.includes('map') ? (
                    <a
                      href={`${Location.pathname}/map`}
                      className='text-white view-map-btn p-2 d-sm-flex d-none align-items-center gap-3'
                    >
                      <span className='d-md-block d-none ps-2'>View Map</span>
                      <span className='view-map-btn-scope d-flex align-items-center  justify-content-center '>
                        <ScopeIcon />
                      </span>
                    </a>
                  ) : (
                    <Link className='text-white view-map-btn p-2 d-sm-flex d-none align-items-center gap-3'>
                      <span className='d-md-block d-none ps-2'>View Map</span>
                      <span className='view-map-btn-scope d-flex align-items-center justify-content-center '>
                        <ScopeIcon />
                      </span>
                    </Link>
                  )}

                  <div className='d-flex align-items-center w-max-content'>
                    <button
                      className='border-0 outline-0 bg-transparent p-0 height-56'
                      data-bs-toggle='modal'
                      data-bs-target='#deactivatemodal'
                    >
                      <FilterIcon />
                    </button>
                  </div>
                  {!Location.pathname.includes('map') ? (
                    <a
                      href={`${Location.pathname}/map`}
                      className='text-white view-map-btn p-2 d-flex d-sm-none align-items-center gap-3 height-56 rounded-3'
                    >
                      <span className='view-map-btn-scope d-flex align-items-center justify-content-center h-100 w-max-content p-1 rounded-3'>
                        <ScopeIcon />
                      </span>
                    </a>
                  ) : (
                    <Link className='text-white view-map-btn p-2 d-flex d-sm-none align-items-center gap-3 height-56 rounded-3'>
                      <span className='view-map-btn-scope d-flex align-items-center justify-content-center h-100 w-max-content p-1 rounded-3'>
                        <ScopeIcon />
                      </span>
                    </Link>
                  )}
                </div>
              </div>
            </div>

            <div className='mt-sm-5 mt-4 mb-5 gap-4 d-flex align-items-start justify-content-between ps-12 pe-12'>
              <div className='search-product d-sm-flex d-none'>
                <input
                  placeholder='Search Product'
                  className='border-0 outline-0 bg-transparent'
                  onChange={e => setSearchTerm(e.target.value)}
                  value={searchTerm}
                />
                <div className='icon-green-bg'>
                  <SearchButtonIcon />
                </div>
              </div>
              <div className={`d-flex gap-3 overflow-x-auto all-products-link ${isLoading && 'blur'}`}>
                {options.map(option => {
                  if (userType === option.userType) {
                    return (
                      <label key={option.value} className={`product-item cr-p ${option.checked ? 'active' : ''}`}>
                        <input
                          disabled={isLoading}
                          className='d-none'
                          type='checkbox'
                          value={option.value}
                          checked={option.checked}
                          aria-disabled={isLoading}
                          onChange={e => handleCheckboxChange(e)}
                        />
                        {option.icon} {option.label}
                      </label>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>

        <div className='container mx-auto '>
          <div className='seeds-card-main row m-0'>
            <div className='d-flex justify-content-center align-items-center'>
              <div className='loader-main '>
                <div className='loader'>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
            {Object.keys(groupData).length > 0 ? (
              Object.keys(groupData)?.map((data, index) => {
                const imageUrl = groupData[data][0]?.photo
                  ? `${process.env.REACT_APP_PORT}/${groupData[data][0]?.photo}`
                  : `${process.env.REACT_APP_PORT}/undefined`;

                const isPlaceholderImage = imageUrl === `${process.env.REACT_APP_PORT}/undefined`;
                return (
                  <div
                    className={`col-xl-3 col-lg-4  col-md-6 mb-4 seed-card-col h-100 ${isLoading ? 'blur' : ''}`}
                    key={index}
                  >
                    <div style={{ height: '23rem' }} className='seed-card h-100 position-relative'>
                      <div className='row m-0 flex-sm-column w-100'>
                        <div className='col-4 col-sm-12 p-0'>
                          {isPlaceholderImage ? (
                            <ImageDisplay
                              className='intro-img cards-image-style'
                              src={
                                'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSZpPDnjQBynSxEHYXo-orWYtvNybtifvhMUdsVjEhuOFpkDYPshISj_TpITVzJullxW4&usqp=CAU'
                              }
                              alt=''
                            />
                          ) : (
                            <ImageDisplay className='intro-img cards-image-style' src={imageUrl} alt='' />
                          )}
                          {/* {userType === "retailer" && (
                            <ButtonToolbar
                              className="like-post cr-p outline-0 "
                              type="button"
                              onClick={() =>
                                favouriteHandler(
                                  currentuserData?._id,
                                  groupData[data][0]?._id,
                                  groupData[data][0]?.category
                                )
                              }
                            >
                              <OverlayTrigger
                                className="h-100 w-100"
                                placement="top"
                                overlay={
                                  groupData[data][0]?.favourite &&
                                    groupData[data][0]?.favourite.includes(currentuserData?._id)
                                    ? tooltip2
                                    : tooltip1
                                }
                              >
                                {groupData[data][0]?.favourite &&
                                  groupData[data][0]?.favourite.includes(
                                    currentuserData?._id
                                  ) ? (
                                  <svg
                                    width={12}
                                    height={12}
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6.372 11.3455C6.168 11.4183 5.832 11.4183 5.628 11.3455C3.888 10.7448 0 8.23897 0 3.99178C0 2.11695 1.494 0.600098 3.336 0.600098C4.428 0.600098 5.394 1.13403 6 1.9592C6.606 1.13403 7.578 0.600098 8.664 0.600098C10.506 0.600098 12 2.11695 12 3.99178C12 8.23897 8.112 10.7448 6.372 11.3455Z"
                                      fill="#BE3F3F"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    width={12}
                                    height={12}
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M6.372 11.3455C6.168 11.4183 5.832 11.4183 5.628 11.3455C3.888 10.7448 0 8.23897 0 3.99178C0 2.11695 1.494 0.600098 3.336 0.600098C4.428 0.600098 5.394 1.13403 6 1.9592C6.606 1.13403 7.578 0.600098 8.664 0.600098C10.506 0.600098 12 2.11695 12 3.99178C12 8.23897 8.112 10.7448 6.372 11.3455Z"
                                      fill="#FFFFFF"
                                    />
                                  </svg>
                                )}
                              </OverlayTrigger>
                            </ButtonToolbar>
                          )} */}
                        </div>
                        <div className='col-8 col-sm-12 p-0'>
                          <div className='ps-sm-0 ps-3'>
                            <Link
                              to={`/home/${userType === 'retailer' ? groupData[data][0]?.category : 'userItem'}/${
                                groupData[data][0]?._id
                              }`}
                              className='text-dark'
                            >
                              <p className='my-sm-4 mb-3 font-24 font-weight-700 cut-text'>
                                {groupData[data][0]?.strainName ||
                                  groupData[data][0]?.productName ||
                                  groupData[data][0]?.postStrain ||
                                  groupData[data][0]?.userId.storeName}
                                <br />
                                {groupData[data][0]?.userId?.retailerType && (
                                  <>
                                    <span className='smaller-text text-capitalize'>
                                      Store Name: {groupData[data][0]?.userId?.storeName}
                                    </span>
                                    <br />
                                  </>
                                )}
                                {groupData[data][0]?.userId?.retailerType && (
                                  <span className='text-grey smaller-text text-capitalize'>
                                    Retailer type:{' '}
                                    {groupData[data][0]?.userId?.retailerType === 'cannabis'
                                      ? 'Cannabis Lounge'
                                      : groupData[data][0]?.userId?.retailerType}
                                  </span>
                                )}
                                {groupData[data][0]?.userId?.userType == 'consumer' && (
                                  <>
                                    <span className='text-grey smaller-text text-capitalize'>
                                      Quantity: {groupData[data][0]?.quantity}
                                    </span>
                                    <br />
                                    <span className='text-grey smaller-text text-capitalize'>
                                      Quantity Type: {groupData[data][0]?.quantityType}
                                    </span>
                                  </>
                                )}
                              </p>
                            </Link>
                            {groupData[data][0]?.userId?.retailerType === 'dispensary' ? (
                              <>
                                <div className='d-flex justify-content-between align-items-center mb-sm-3 mb-2 gap-sm-3 gap-2'>
                                  <DistanceIcon />
                                  <span className='cut-text font-18 font-weight-500'>
                                    {groupData[data][0]?.distance} Away
                                  </span>

                                  {/* <span className="d-flex gap-2 align-items-center font-18 font-weight-500 w-50">
                                    <DispensaryFlower />
                                    <span className="cut-text">
                                      {groupData[data][0]?.userId?.storeName}
                                    </span>
                                  </span> */}
                                </div>

                                <span className='d-flex gap-2 align-items-center font-18 font-weight-500 '>
                                  <LocationIcon />
                                  <span className='cut-text'>{groupData[data][0]?.userId?.location?.address}</span>
                                </span>
                                <span className='d-flex gap-2 align-items-center font-18 font-weight-500 mb-sm-4 pb-sm-1 mb-2'></span>
                                <div className='d-flex justify-content-between align-items-center gap-sm-2 gap-3 flex-sm-nowrap flex-wrap'>
                                  <div className='d-flex gap-2 align-items-center flex-wrap'>
                                    <span className='d-flex gap-2 align-items-center font-24 font-weight-700'>
                                      <RatingIcon />
                                      {groupData[data][0]?.ratingsAverage}
                                    </span>
                                    <span className='font-14-100 text-grey font-weight-400'>
                                      (
                                      {groupData[data][0]?.ratingsQuantity === 0
                                        ? 'No Reviews'
                                        : `${groupData[data][0]?.ratingsQuantity} Reviews`}
                                      )
                                    </span>
                                  </div>
                                  <Link
                                    to={`/home/${userType === 'retailer' ? groupData[data][0]?.category : 'userItem'}/${
                                      groupData[data][0]?._id
                                    }`}
                                    className='green-btn-outline bg-primary-green text-white ps-3 pe-1 d-flex align-items-center justify-content-between font-18 py-sm-3 py-2 gap-2 w-max-content'
                                  >
                                    <span>View Product </span>
                                    <span className='icon-green-bg bg-light-green'>
                                      <FavouriteIcon />
                                    </span>
                                  </Link>
                                </div>
                              </>
                            ) : (
                              <>
                                {groupData[data][0]?.userId?.retailerType === 'headshop' ? (
                                  <>
                                    <div className='d-flex justify-content-between align-items-center mb-sm-3 mb-2 gap-sm-3 gap-2'>
                                      <DistanceIcon />
                                      <span className='cut-text font-18 font-weight-500'>
                                        {groupData[data][0]?.distance} Away
                                      </span>
                                      {/* <span className="d-flex gap-2 align-items-center font-18 font-weight-500 w-50">
                                        <FlavorIcon />
                                        <span className="cut-text">
                                          Flavour: Mint
                                        </span>
                                      </span> */}
                                    </div>
                                    <span className='d-flex gap-2 align-items-center font-18 font-weight-500 mb-sm-4 pb-sm-1 mb-2'>
                                      <LocationIcon />
                                      <span className='cut-text'>{groupData[data][0]?.userId?.location?.address}</span>
                                    </span>
                                    <div className='d-flex justify-content-between align-items-center gap-sm-2 gap-3 flex-sm-nowrap flex-wrap'>
                                      <div className='d-flex gap-2 align-items-center flex-wrap'>
                                        <span className='d-flex gap-2 align-items-center font-24 font-weight-700'>
                                          <RatingIcon />
                                          {groupData[data][0]?.ratingsAverage}
                                        </span>
                                        <span className='font-14-100 text-grey font-weight-400'>
                                          (
                                          {groupData[data][0]?.ratingsQuantity === 0
                                            ? 'No Reviews'
                                            : `${groupData[data][0]?.ratingsQuantity} Reviews`}
                                          )
                                        </span>
                                      </div>
                                      <Link
                                        to={`/home/${
                                          userType === 'retailer' ? groupData[data][0]?.category : 'userItem'
                                        }/${groupData[data][0]?._id}`}
                                        className='green-btn-outline bg-primary-green text-white ps-3 pe-1 d-flex align-items-center justify-content-between font-18 py-sm-3 py-2 gap-2 w-max-content'
                                      >
                                        <span>View Product </span>
                                        <span className='icon-green-bg bg-light-green'>
                                          <FavouriteIcon />
                                        </span>
                                      </Link>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {groupData[data][0]?.userId?.retailerType === 'cannabis' ? (
                                      <>
                                        <div className='d-flex justify-content-between align-items-center mb-sm-3 mb-2 gap-sm-3 gap-2'>
                                          <DistanceIcon />
                                          <span className='cut-text font-18 font-weight-500'>
                                            {groupData[data][0]?.distance}
                                            Away
                                          </span>
                                        </div>
                                        {/* <div className="d-flex justify-content-between align-items-center mb-sm-3 mb-2 gap-sm-3 gap-2">
                                          <span className="d-flex gap-2 align-items-center font-18 font-weight-500 w-50">
                                            <PriceIcon />
                                            <span className="cut-text">
                                              Entry Fees:{" "}
                                              {`$${groupData[data][0]?.entryFee}`}
                                            </span>
                                          </span>
                                        </div> */}
                                        <span className='d-flex gap-2 align-items-center font-18 font-weight-500 mb-sm-4 pb-sm-1 mb-2'>
                                          <LocationIcon />
                                          <span className='cut-text'>
                                            {groupData[data][0]?.userId?.location?.address}
                                          </span>
                                        </span>

                                        <div className='d-flex justify-content-between align-items-center gap-sm-2 gap-3 flex-sm-nowrap flex-wrap'>
                                          <div className='d-flex gap-2 align-items-center flex-wrap'>
                                            <span className='d-flex gap-2 align-items-center font-24 font-weight-700'>
                                              <RatingIcon />
                                              {groupData[data][0]?.ratingsAverage}
                                            </span>
                                            <span className='font-14-100 text-grey font-weight-400'>
                                              (
                                              {groupData[data][0]?.ratingsQuantity === 0
                                                ? 'No Reviews'
                                                : `${groupData[data][0]?.ratingsQuantity} Reviews`}
                                              )
                                            </span>
                                          </div>
                                          <Link
                                            to={`/home/${
                                              userType === 'retailer' ? groupData[data][0]?.category : 'userItem'
                                            }/${groupData[data][0]?._id}`}
                                            className='green-btn-outline bg-primary-green text-white ps-3 pe-1 d-flex align-items-center justify-content-between font-18 py-sm-3 py-2 gap-2 w-max-content'
                                          >
                                            <span>View Product </span>
                                            <span className='icon-green-bg bg-light-green'>
                                              <FavouriteIcon />
                                            </span>
                                          </Link>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {groupData[data][0]?.userId?.retailerType === 'seedbank' ? (
                                          <>
                                            <div className='d-flex justify-content-between align-items-center mb-sm-3 mb-2 gap-sm-3 gap-2'>
                                              <DistanceIcon />
                                              <span className='cut-text font-18 font-weight-500'>
                                                {groupData[data][0]?.distance} Away
                                              </span>
                                              {/* <span className="d-flex gap-2 align-items-center font-18 font-weight-500 w-50">
                                                <CountIcon />
                                                <span className="cut-text">
                                                  {quantity.includes(
                                                    groupData[data][0].quantity
                                                  )
                                                    ? `Grams: ${groupData[data][0].quantity}`
                                                    : `Seeds: ${groupData[data][0].quantity}`}
                                                </span>
                                              </span> */}
                                            </div>

                                            <span className='d-flex gap-2 align-items-center font-18 font-weight-500'>
                                              <LocationIcon />
                                              <span className='cut-text'>
                                                {groupData[data][0]?.userId?.location?.address}
                                              </span>
                                            </span>

                                            <span className='d-flex gap-2 align-items-center font-18 font-weight-500 mb-sm-4 pb-sm-1'></span>

                                            <div className='d-flex justify-content-between align-items-center gap-sm-2 gap-3 flex-sm-nowrap flex-wrap'>
                                              <div className='d-flex gap-2 align-items-center flex-wrap'>
                                                <span className='d-flex gap-2 align-items-center font-24 font-weight-700'>
                                                  <RatingIcon />
                                                  {groupData[data][0]?.ratingsAverage}
                                                </span>
                                                <span className='font-14-100 text-grey font-weight-400'>
                                                  (
                                                  {groupData[data][0]?.ratingsQuantity === 0
                                                    ? 'No Reviews'
                                                    : `${groupData[data][0]?.ratingsQuantity} Reviews`}
                                                  )
                                                </span>
                                              </div>
                                              <Link
                                                to={`/home/${
                                                  userType === 'retailer' ? groupData[data][0]?.category : 'userItem'
                                                }/${groupData[data][0]?._id}`}
                                                className='green-btn-outline bg-primary-green text-white ps-3 pe-1 d-flex align-items-center justify-content-between font-18 py-sm-3 py-2 gap-2 w-max-content'
                                              >
                                                <span>View Product </span>
                                                <span className='icon-green-bg bg-light-green'>
                                                  <FavouriteIcon />
                                                </span>
                                              </Link>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <div className='d-flex justify-content-between align-items-center mb-sm-3 mb-2 gap-sm-3 gap-2'>
                                              <DistanceIcon />
                                              <span className='cut-text font-18 font-weight-500'>
                                                {groupData[data][0]?.distance} Away
                                              </span>

                                              {/* <span className="d-flex gap-2 align-items-center font-18 font-weight-500 w-50">
                                                <BudsType />
                                                <span className="cut-text">
                                                  Type :{" "}
                                                  {
                                                    groupData[data][0]
                                                      ?.strainType
                                                  }
                                                </span>
                                              </span> */}
                                            </div>
                                            {groupData[data][0]?.userId?.retailerType === 'growdepot' && (
                                              <span className='d-flex gap-2 align-items-center font-18 font-weight-500 mb-sm-4 pb-sm-1 mb-2'>
                                                <LocationIcon />
                                                <span className='cut-text'>
                                                  {groupData[data][0]?.userId?.location?.address}
                                                </span>
                                              </span>
                                            )}

                                            <div className='d-flex justify-content-between align-items-center gap-sm-2 gap-3 flex-sm-nowrap flex-wrap'>
                                              <div className='d-flex gap-2 align-items-center flex-wrap'>
                                                <span className='d-flex gap-2 align-items-center font-24 font-weight-700'>
                                                  <RatingIcon />
                                                  {groupData[data][0]?.ratingsAverage}
                                                </span>
                                                <span className='font-14-100 text-grey font-weight-400'>
                                                  (
                                                  {groupData[data][0]?.ratingsQuantity === 0
                                                    ? 'No Reviews'
                                                    : `${groupData[data][0]?.ratingsQuantity} Reviews`}
                                                  )
                                                </span>
                                              </div>
                                              <Link
                                                to={`/home/${
                                                  userType === 'retailer' ? groupData[data][0]?.category : 'userItem'
                                                }/${groupData[data][0]?._id}`}
                                                className='green-btn-outline bg-primary-green text-white ps-3 pe-1 d-flex align-items-center justify-content-between font-18 py-sm-3 py-2 gap-2 w-max-content'
                                              >
                                                <span>View Product </span>
                                                <span className='icon-green-bg bg-light-green'>
                                                  <FavouriteIcon />
                                                </span>
                                              </Link>
                                            </div>
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}

                            {groupData[data][0]?.timing && (
                              <span className='d-flex gap-2 align-items-center font-18 font-weight-500  mb-sm-3 mb-2'>
                                <TimerIcon />
                                {groupData[data][0]?.timing}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                {!isLoading && (
                  <div className='d-flex justify-content-center w-100'>
                    <EmptyDataImage />
                  </div>
                )}
              </>
            )}
            {data?.totalRecords > 10 && (
              <div className='d-flex justify-content-center mt-4'>
                <PaginationControl
                  page={page}
                  between={3}
                  total={data?.totalRecords}
                  limit={data?.limit}
                  changePage={page => pageHandler(page)}
                  ellipsis={1}
                />
              </div>
            )}
          </div>
        </div>

        {/* filter modal */}
        <div
          className='modal fade'
          id='deactivatemodal'
          data-bs-backdrop='static'
          data-bs-keyboard='false'
          tabIndex={-1}
          aria-labelledby='staticBackdropLabel'
          aria-hidden='true'
        >
          <div className='modal-dialog custom-model w-max-content modal-dialog-centered mx-auto filter-model'>
            <div className='modal-content justify-content-center p-4'>
              <div className='d-flex justify-content-between align-items-center'>
                <p className='font-32 font-weight-800 text-center'>Filter your search</p>
                <span className='cr-p' data-bs-dismiss='modal'>
                  <CrossBorderIcon />
                </span>
              </div>
              <form>
                <div className='d-flex flex-column align-items-start justify-content-center mt-4 pt-2'>
                  <div className='p-0 bg-transparent border-0 w-100'>
                    {/* <LoadScript
                      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP}
                      libraries={libraries}
                    >
                      <StandaloneSearchBox
                        onLoad={(ref) => (inputRef1.current = ref)}
                        onPlacesChanged={handlePlaceChanged}
                      >
                        <div className="form-control h-auto p-0 bg-transparent border-0">
                          <label className="mb-2 font-weight-600 font-18-100">
                            Search an area
                          </label>
                          <input
                            onChange={(e) =>
                              setFilter((prevState) => ({
                                ...prevState,
                                area: e.target.value,
                              }))
                            }
                            value={filter.area}
                            type="text"
                            className="auth-input bg-white height-42"
                            placeholder="Enter here..."
                            name="area"
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </StandaloneSearchBox>
                    </LoadScript> */}
                    <AddressInput
                      setAddressProp={address =>
                        setFilter(prevState => ({
                          ...prevState,
                          area: address
                        }))
                      }
                      inputLabel='Search an area'
                      className='height-42'
                      addressValue={filter.area}
                      isDark={false}
                    />
                  </div>
                  <div className='d-flex flex-column align-items-start justify-content-center w-100 gap-2 mb-4'>
                    {/* <label className="font-weight-600 font-18-100">
                      Distance
                    </label>
                     <input
                      type="range"
                      className="form-control-range w-100"
                      min="0"
                      max="50"
                      step="10"
                      name="radius"
                      value={filter.radius}
                      onChange={(e) =>
                        setFilter((prevState) => ({
                          ...prevState,
                          radius: e.target.value,
                        }))
                      }
                    ></input>
                    <p className="rangetext d-flex w-100 justify-content-between ">
                      <span>All</span>
                      <span></span>
                      <span>0-10km</span>
                      <span>0-20km</span>
                      <span>0-30km</span>
                      <span>0-40km</span>
                      <span>0-50km</span>
                    </p> */}
                    <label className='font-weight-600 font-18-100 d-flex justify-content-between w-100'>
                      <span className='text-dark'>Distance(km)</span>
                      <span className='text-dark'>
                        {minMax.minValue}-{minMax.maxValue}
                      </span>
                    </label>
                    <MultiRangeSlider
                      className='shadow-none border-0 py-0 px-2 py-2 w-100'
                      min={0}
                      max={250}
                      minValue={minMax.minValue}
                      maxValue={minMax.maxValue}
                      ruler={false}
                      label={false}
                      barLeftColor='white'
                      barInnerColor='#5D8B2F'
                      barRightColor='white'
                      thumbLeftColor='white'
                      thumbRightColor='white'
                      onInput={handleInput}
                    />
                  </div>
                </div>

                {/* if any cannabisLounge or headShop then did not show this div filter */}
                {quantityFilter !== 'none' && (
                  <div className='gap-3 mb-3'>
                    {/* {quantityFilter === "all" || quantityFilter === "" ? ( */}
                    <div className='btn-group btn-group-toggle height-42 mb-4 btn-group-dash' data-toggle='buttons'>
                      <label className='btn font-14 bg-grey active d-flex align-items-center py-3 px-sm-3 px-2'>
                        <input
                          type='radio'
                          name='options'
                          id='Grams'
                          autoComplete='off'
                          readOnly
                          checked={type === 'Grams'}
                          onChange={handleChange}
                          value='Grams'
                        />
                        <span className='pl-2'>Grams</span>
                      </label>
                      <label className='btn font-14 bg-grey d-flex align-items-center px-sm-3 px-2'>
                        <input
                          type='radio'
                          name='options'
                          id='Seeds'
                          value='Seeds'
                          autoComplete='off'
                          checked={type === 'Seeds'}
                          onChange={handleChange}
                        />
                        <span className='pl-2'>Seeds</span>
                      </label>{' '}
                      <label className='btn font-14 bg-grey d-flex align-items-center'>
                        <input
                          type='radio'
                          name='options'
                          id='All'
                          value='All'
                          autoComplete='off'
                          checked={type === 'All'}
                          onChange={handleChange}
                        />
                        <span className='pl-2'>All</span>
                      </label>
                    </div>
                    {/* ) : (
                      ""
                    )} */}
                    {customType === 'CustomSelect' && type !== 'All' && (
                      <div className='form-control h-auto p-0 mb-5 bg-transparent border-0'>
                        <select
                          className='auth-input bg-white height-42 m-0'
                          required
                          name='quantity'
                          onChange={e => formHandler(e)}
                        >
                          <option value={''}>- All -</option>
                          <option value={type === 'Seeds' ? '1-4' : '1-7'}>
                            {type === 'Seeds' ? '1-4 Seeds' : '1-7 Grams'}
                          </option>
                          <option value={type === 'Seeds' ? '5-10' : '7-14'}>
                            {type === 'Seeds' ? '5-10 Seeds' : '7-14 Grams'}
                          </option>
                          <option value={type === 'Seeds' ? '11-15' : '14-30'}>
                            {type === 'Seeds' ? '11-15 Seeds' : '14-30 Grams'}
                          </option>
                          {type === 'Seeds' && <option value={'16-20'}>{'16-20 Seeds'}</option>}
                        </select>
                      </div>
                    )}
                  </div>
                )}

                <div className='d-flex flex-sm-row flex-column align-items-center gap-4 justify-content-center w-100 mt-4 flex-wrap'>
                  <button
                    className='light-red-btn-outline text-white custom-w min-width-208 height-42'
                    onClick={clearFilterHandler}
                    data-bs-dismiss='modal'
                  >
                    Clear Filter
                  </button>
                  <button
                    className='green-btn-outline text-primary-green custom-w min-width-208 height-42'
                    data-bs-dismiss='modal'
                    type='button'
                  >
                    Cancel
                  </button>
                  <button
                    className='green-btn custom-w min-width-208 height-42'
                    onClick={e => submitHandler(e)}
                    data-bs-dismiss='modal'
                  >
                    Apply
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllProductsDashboard;
const tooltip1 = <Tooltip id='tooltip'>Click to add as favorite</Tooltip>;
const tooltip2 = <Tooltip id='tooltip'>Remove as favourite</Tooltip>;
