import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Axios from '../../axios/Axios';
import { useNavigate } from 'react-router-dom';
import useSessionStorage from '../../hooks/useSessionStorage';

const UpgradePremiumModal = () => {
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);
  const [popupPlanData, setPopupedPlanData] = useState(null);
  const [popupType, setPopupType] = useState(null);
  const [strainPostCount] = useSessionStorage('strain-post-count', 0);
  const [strainSearchCount] = useSessionStorage('strain-search-count', 0);
  const popupShown = sessionStorage.getItem('5min-interval-premium-popup-shown');
  const [isFirstMessageSent] = useSessionStorage('isFirstMessageSent', false);
  const [isFirstReviweSubmited] = useSessionStorage('isFirstReviweSubmited', false);

  const fetchUserSubscriptions = async () => {
    try {
      const currentUser = localStorage.getItem('userdata');
      let data = JSON.parse(currentUser);

      const userSubscriptions = await Axios.get(
        `${process.env.REACT_APP_API_URI}subscription/user-subscription/${data?._id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('user-token')}`
          }
        }
      );

      const url =
        data.userType === 'retailer'
          ? `${process.env.REACT_APP_API_URI}plan/${data.retailerType}`
          : `${process.env.REACT_APP_API_URI}plan/`;

      const plansResponse = await Axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('user-token')}`
        }
      });

      let subscribedPlanIds = userSubscriptions?.data?.userSubscriptions?.map(data => data?.planId);
      let isYearlyPlanSubscribed = plansResponse?.data?.plans
        ?.filter(plan => subscribedPlanIds?.includes(plan?._id) && (plan?.type == 'grow' || plan?.type == 'combine'))
        .some(plan => plan?.interval == 'year');
      if (isYearlyPlanSubscribed) {
        setPopupedPlanData(null);
      } else {
        let filtersPlansData = plansResponse?.data?.plans
          ?.filter(
            plan =>
              !subscribedPlanIds?.includes(plan?._id) &&
              (plan?.type == 'grow' || plan?.type == 'combine') &&
              plan?.interval == 'year'
          )
          ?.map(plan => ({
            ...plan,
            subscription: userSubscriptions?.data?.userSubscriptions?.find(
              subscription => subscription?.planId === plan._id
            )
          }));

        let highestPricedPlan = filtersPlansData?.reduce((maxPlan, currentPlan) =>
          currentPlan.price > maxPlan.price ? currentPlan : maxPlan
        );
        setPopupedPlanData(highestPricedPlan);
      }
    } catch (error) {
      console.log(error?.response?.data || error);
    }
  };

  const checkPopups = () => {
    // First strain post
    if (strainPostCount == 1 && !sessionStorage.getItem('strain-post-popup-shown')) {
      fetchUserSubscriptions();
      setPopupType('strain-post-popup-shown');
      setShowPopup(true);
      sessionStorage.setItem('strain-post-popup-shown', 'true');
      return;
    }

    // Third strain search
    if (strainSearchCount == 3 && !sessionStorage.getItem('strain-search-popup-shown')) {
      fetchUserSubscriptions();
      setPopupType('strain-search-popup-shown');
      setShowPopup(true);
      sessionStorage.setItem('strain-search-popup-shown', 'true');
      return;
    }

    // First Message Sent
    if (isFirstMessageSent && !sessionStorage.getItem('first-message-sent-popup-shown')) {
      fetchUserSubscriptions();
      setPopupType('first-message-sent-popup-shown');
      setShowPopup(true);
      sessionStorage.setItem('first-message-sent-popup-shown', 'true');
      return;
    }

    // First Review Posted
    if (isFirstReviweSubmited && !sessionStorage.getItem('first-review-popup-shown')) {
      fetchUserSubscriptions();
      setPopupType('first-review-popup-shown');
      setShowPopup(true);
      sessionStorage.setItem('first-review-popup-shown', 'true');
      return;
    }

    // 5-minutes after sign-up
    if (!popupShown) {
      setTimeout(() => {
        fetchUserSubscriptions();
        setPopupType('5min-interval-premium-popup');
        setShowPopup(true);
        sessionStorage.setItem('5min-interval-premium-popup-shown', 'true');
      }, 300000);
      return;
    }
  };

  useEffect(() => {
    checkPopups();
  }, []);

  useEffect(() => {
    checkPopups();
  }, [strainPostCount, strainSearchCount, isFirstMessageSent, isFirstReviweSubmited]);

  const handleClose = () => {
    setShowPopup(false);
    setPopupType(null);
  };

  if (!popupPlanData) {
    return false;
  }

  return (
    <Modal
      show={showPopup}
      onHide={() => setShowPopup(false)}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      backdrop={true}
      className='d-flex align-items-center justify-content-center w-100'
    >
      {popupType == '5min-interval-premium-popup' && (
        <Min5PopupCountModal
          navigate={navigate}
          setShowPopup={setShowPopup}
          popupPlanData={popupPlanData}
          handleClose={handleClose}
        />
      )}
      {popupType == 'strain-post-popup-shown' && (
        <FirstStrainPostModal
          navigate={navigate}
          setShowPopup={setShowPopup}
          popupPlanData={popupPlanData}
          handleClose={handleClose}
        />
      )}
      {popupType == 'strain-search-popup-shown' && (
        <SearchStrainShowModal
          navigate={navigate}
          setShowPopup={setShowPopup}
          popupPlanData={popupPlanData}
          handleClose={handleClose}
        />
      )}
      {popupType == 'first-message-sent-popup-shown' && (
        <FirstMesssageSentShowModal
          navigate={navigate}
          setShowPopup={setShowPopup}
          popupPlanData={popupPlanData}
          handleClose={handleClose}
        />
      )}
      {popupType == 'first-review-popup-shown' && (
        <FirstReviewModalShow
          navigate={navigate}
          setShowPopup={setShowPopup}
          popupPlanData={popupPlanData}
          handleClose={handleClose}
        />
      )}
    </Modal>
  );
};

const Min5PopupCountModal = ({ navigate, setShowPopup, popupPlanData, handleClose }) => {
  return (
    <React.Fragment>
      <Modal.Header className='bg-dark-brown text-white border-0'>
        <Modal.Title id='contained-modal-title-vcenter'>Upgrade to Premium</Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-4 d-flex flex-column h-100 bg-dark-brown text-white'>
        <div className='d-flex align-items-center justify-content-center'>
          Unlock exclusive access, extra savings, and rare opportunities. Upgrade now and enjoy the benefits!
        </div>
        <CommonModalBody
          navigate={navigate}
          popupPlanData={popupPlanData}
          setShowPopup={setShowPopup}
          handleClose={handleClose}
        />
      </Modal.Body>
    </React.Fragment>
  );
};

const FirstStrainPostModal = ({ navigate, setShowPopup, popupPlanData, handleClose }) => {
  return (
    <React.Fragment>
      <Modal.Header className='bg-dark-brown text-white border-0'>
        <Modal.Title id='contained-modal-title-vcenter'>You're on a Roll!</Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-4 d-flex flex-column h-100 bg-dark-brown text-white'>
        <div className='d-flex align-items-center justify-content-center'>
          Unlock Premium to swap more strains & connect faster!
        </div>
        <CommonModalBody
          navigate={navigate}
          popupPlanData={popupPlanData}
          setShowPopup={setShowPopup}
          handleClose={handleClose}
        />
      </Modal.Body>
    </React.Fragment>
  );
};
const SearchStrainShowModal = ({ navigate, setShowPopup, popupPlanData, handleClose }) => {
  return (
    <React.Fragment>
      <Modal.Header className='bg-dark-brown text-white border-0'>
        <Modal.Title id='contained-modal-title-vcenter'>Looking for the best strains?</Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-4 d-flex flex-column h-100 bg-dark-brown text-white'>
        <div className='d-flex align-items-center justify-content-center'>
          Go Premium & access more listings, faster swaps, and exclusive deals!
        </div>
        <CommonModalBody
          navigate={navigate}
          popupPlanData={popupPlanData}
          setShowPopup={setShowPopup}
          handleClose={handleClose}
        />
      </Modal.Body>
    </React.Fragment>
  );
};
const FirstMesssageSentShowModal = ({ navigate, setShowPopup, popupPlanData, handleClose }) => {
  return (
    <React.Fragment>
      <Modal.Header className='bg-dark-brown text-white border-0'>
        <Modal.Title id='contained-modal-title-vcenter'>Making connections?</Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-4 d-flex flex-column h-100 bg-dark-brown text-white'>
        <div className='d-flex align-items-center justify-content-center'>
          Upgrade to Premium for unlimited messaging & better networking!
        </div>
        <CommonModalBody
          navigate={navigate}
          popupPlanData={popupPlanData}
          setShowPopup={setShowPopup}
          handleClose={handleClose}
        />
      </Modal.Body>
    </React.Fragment>
  );
};
const FirstReviewModalShow = ({ navigate, setShowPopup, popupPlanData, handleClose }) => {
  return (
    <React.Fragment>
      <Modal.Header className='bg-dark-brown text-white border-0'>
        <Modal.Title id='contained-modal-title-vcenter'>Love reviewing? Unlock More!</Modal.Title>
      </Modal.Header>
      <Modal.Body className='p-4 d-flex flex-column h-100 bg-dark-brown text-white'>
        <div className='d-flex align-items-center justify-content-center'>
          Go Premium for discounts, free events & better social connections!
        </div>
        <CommonModalBody
          navigate={navigate}
          popupPlanData={popupPlanData}
          setShowPopup={setShowPopup}
          handleClose={handleClose}
        />
      </Modal.Body>
    </React.Fragment>
  );
};

const CommonModalBody = ({ popupPlanData, navigate, setShowPopup, handleClose }) => {
  return (
    <div className=''>
      <div className='auth-subscription mt-0'>
        <ul>
          {(popupPlanData?.description || []).map((data, index) => {
            return (
              <li key={index}>
                <h4 className='font-16-social text-white font-weight-400 line-height-20px'>{data}</h4>
              </li>
            );
          })}
        </ul>
        <div className='auth-input h-auto mt-4 text-dark p-3'>
          <div className='d-flex flex-column'>
            <div className='d-flex justify-content-between text-dark gap-3 align-items-center border-bottom border-grey pb-3 flex-wrap'>
              <span className='font-16-social text-white'>Subscription Type</span>
              <h4 className='font-18-social text-white'>{popupPlanData?.name}</h4>
            </div>
            <div className='d-flex justify-content-between text-dark gap-3 align-items-center pt-3'>
              <span className='font-16-social text-white'>Total</span>
              <h4 className='font-18-social text-white'>${popupPlanData?.price}</h4>
            </div>
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-end gap-2 mt-2'>
          <button className='red-btn w-max-content px-3 mt-3 ' onClick={handleClose}>
            May Be Later
          </button>
          <button
            className='green-btn w-max-content px-3 mt-3 '
            onClick={() => {
              navigate('/home/subscriptiondetail');
              setShowPopup(false);
            }}
          >
            Upgrade Now
          </button>
        </div>
      </div>
    </div>
  );
};
export default UpgradePremiumModal;
