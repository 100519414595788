import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ProtectedRoutes } from '../utils/ProtectedRoutes';
import HomePage from '../Pages/Home';
import Seeds from '../Components/Dashboard Components/Seeds';
import SeedMap from '../Components/ViewMap/Seed';
import Buds from '../Components/Dashboard Components/Buds';
import BudsMap from '../Components/ViewMap/Buds';
import Dispensary from '../Components/Dashboard Components/Dispensary';
import Growdepot from '../Components/Dashboard Components/Growdepot';
import DispensaryMap from '../Components/ViewMap/Dispensary';
import Cannabis from '../Components/Dashboard Components/Cannabis';
import CannabisMap from '../Components/ViewMap/Cannabis';
import Growdepotmap from '../Components/ViewMap/Growdepotmap';
import HeadShopMap from '../Components/ViewMap/HeadShop';
import DispensaryProfileDetail from '../Pages/Dispensary User Profile';
import Gowdepaotuser from '../Pages/GrowdepotUserProfile';
import CannabisProfileDetail from '../Pages/Cannabis User Profile';
import HeadProfileDetail from '../Pages/Head User Profile';
import FavoriteProduct from '../Pages/Favorite Products';
import UserProfile from '../Pages/User Profile';
import EditProfile from '../Components/User Profile Detail Options/EditProfile';
import DeleteProfile from '../Components/User Profile Detail Options/DeleteAccount';
import AppLayout from '../Components/App Layout';
import HeadShop from '../Components/Dashboard Components/HeadShop';
import HeadShopRegister from '../Pages/Head Shop';
import LoginPage from '../Pages/Login Page';
import AgeVerifyPage from '../Pages/Age Verify';
import SignUpPage from '../Pages/Sign up';
import RetailerType from '../Pages/Retailer Type';
import DispensaryType from '../Pages/Dispensary';
import SeedStore from '../Pages/Seed Store';
import CannabisLounge from '../Pages/Cannabis Lounge';
import AddAddressPage from '../Pages/Add Adress';
import ResponsivePage from '../Pages/Response Page';
import BudSeedPage from '../Pages/Bud Seed';
import TermsConditionsPage from '../Pages/Terms and Conditions';
import PrivacyPolicy from '../Pages/PrivacyPolicy/PrivacyPolicy';
import NotFound from '../Pages/Not Found';
import RegistrationLayout from '../Components/Registration';
import SeedUserProfile from '../Pages/Seed User Profile';
import ProductUserProfile from '../Pages/Product User Profile';
import Chat from '../Pages/Chat';
import SocialAuthLayout from '../Components/Social Layout/SocialAuthLayout';
import SocialSignUp from '../Components/Social App/SocialSignup';
import SocialSummary from '../Components/Social App/Summary';
import SocialUserDetail from '../Components/Social App/UserDetail';
import SocialUserBio from '../Components/Social App/UserBio';
import SocialNotice from '../Components/Social App/Notice';
import SocialSubscription from '../Components/Social App/SocialSubscription';
import SocialTerms from '../Components/Social App/SocialTerms';
import SocialPolicy from '../Components/Social App/SocialPolicy';
import SocialUploadPicture from '../Components/Social App/UploadPicture';
import LookingFor from '../Components/Social App/LookingFor';
import SocialProfile from '../Components/Social App/SocialProfile';
import SocialLayout from '../Components/Social Layout';
import SocialDashboard from '../Components/Social App/Dashboard';
import SocialPosts from '../Components/Social App/Posts';
import { RegisteredRoutes } from '../utils/RegisterRoutes';
import AllData from '../Components/Dashboard Components/AllData';
import AllDataMapView from '../Components/ViewMap/ShowAllProduct';
import ConsumerDetailPage from '../Pages/Consumer Detail Page';
import AllDataRetailer from '../Components/Dashboard Components/AllDataRetailer';
import ForgotPasswordPage from '../Pages/Forgot Password';
import ResetPasswordPage from '../Pages/Reset Password';
import SocialMatch from '../Components/Social App/SocialMatch';
import SocialSetting from '../Components/Social App/Socialsetting';
import AuthSubscription from '../Pages/Auth Subscription';
import DashboardSubscription from '../Pages/Dashboard Subscription';
import MatchProfile from '../Components/Social App/MatchProfile';
import { UserProtectedRoutes } from '../utils/ProtectedRoutes/userProtectedRoutes';
import SubscribedPlan from '../Pages/Dashboard Subscription/Subscribed';
import CancelSubscription from '../Pages/Cancel Subscription';

const NavigationRoutes = () => {
  const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
  const [currentuserData, setcurrentuserData] = useState();

  useEffect(() => {
    const currentUser = localStorage.getItem('userdata');
    let data = JSON.parse(currentUser);
    setcurrentuserData(data);
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      const currentUser = localStorage.getItem('userdata');
      let data = JSON.parse(currentUser);
      setcurrentuserData(data);
    };
  }, []);

  return (
    <>
      <Routes>
        {/* Registration Route */}
        <Route
          path='/'
          element={
            <RegisteredRoutes
              component={
                <RegistrationLayout>
                  <LoginPage />
                </RegistrationLayout>
              }
            />
          }
        />

        <Route
          path='/login'
          element={
            <RegisteredRoutes
              component={
                <RegistrationLayout>
                  <LoginPage />
                </RegistrationLayout>
              }
            />
          }
        />
        <Route
          path='/forgotpassword'
          element={
            <RegisteredRoutes
              component={
                <RegistrationLayout>
                  <ForgotPasswordPage />
                </RegistrationLayout>
              }
            />
          }
        />
        <Route
          path='/resetpassword'
          element={
            <RegisteredRoutes
              component={
                <RegistrationLayout>
                  <ResetPasswordPage />
                </RegistrationLayout>
              }
            />
          }
        />
        <Route
          path='/age'
          element={
            <RegisteredRoutes
              component={
                <RegistrationLayout>
                  <AgeVerifyPage />
                </RegistrationLayout>
              }
            />
          }
        />
        <Route
          path='/signup'
          element={
            <RegisteredRoutes
              component={
                <RegistrationLayout>
                  <SignUpPage />
                </RegistrationLayout>
              }
            />
          }
        />
        <Route
          path='/retailer'
          element={
            <ProtectedRoutes
              component={
                <RegistrationLayout>
                  <RetailerType />
                </RegistrationLayout>
              }
            />
          }
        />

        <Route
          path='/dispensary'
          element={
            <ProtectedRoutes
              component={
                <RegistrationLayout>
                  <DispensaryType />
                </RegistrationLayout>
              }
            />
          }
        />

        <Route
          path='/headshop'
          element={
            <ProtectedRoutes
              component={
                <RegistrationLayout>
                  <HeadShopRegister />
                </RegistrationLayout>
              }
            />
          }
        />

        <Route
          path='/seedstore'
          element={
            <ProtectedRoutes
              component={
                <RegistrationLayout>
                  <SeedStore />
                </RegistrationLayout>
              }
            />
          }
        />

        <Route
          path='/cannabis'
          element={
            <ProtectedRoutes
              component={
                <RegistrationLayout>
                  <CannabisLounge />
                </RegistrationLayout>
              }
            />
          }
        />

        <Route
          path='/address'
          element={
            <ProtectedRoutes
              component={
                <RegistrationLayout>
                  <AddAddressPage />
                </RegistrationLayout>
              }
            />
          }
        />
        <Route
          path='/subscription'
          element={
            <ProtectedRoutes
              component={
                <RegistrationLayout>
                  <AuthSubscription />
                </RegistrationLayout>
              }
            />
          }
        />

        <Route
          path='/response'
          element={
            <ProtectedRoutes
              component={
                <RegistrationLayout>
                  <ResponsivePage />
                </RegistrationLayout>
              }
            />
          }
        />

        <Route
          path='/budseed'
          element={
            <ProtectedRoutes
              component={
                <RegistrationLayout>
                  <BudSeedPage />
                </RegistrationLayout>
              }
            />
          }
        />

        <Route
          path='/terms'
          element={
            <RegisteredRoutes
              component={
                <RegistrationLayout>
                  <TermsConditionsPage />
                </RegistrationLayout>
              }
            />
          }
        />
        <Route
          path='/privacypolicy'
          element={
            <RegisteredRoutes
              component={
                <RegistrationLayout>
                  <PrivacyPolicy />
                </RegistrationLayout>
              }
            />
          }
        />
        <Route path='*' element={<NotFound />} />

        {/* Dashboard Route  */}
        <Route
          path='/home/retailer-dashboard'
          element={
            <UserProtectedRoutes userType='retailer'>
              <AppLayout>
                <AllDataRetailer />
              </AppLayout>
            </UserProtectedRoutes>
          }
        />
        <Route
          path='/home'
          element={
            <UserProtectedRoutes userType='consumer'>
              <AppLayout>
                <AllData />
              </AppLayout>
            </UserProtectedRoutes>
          }
        />

        <Route
          path='/home/map'
          element={
            <UserProtectedRoutes userType='consumer'>
              <AppLayout>
                <AllDataMapView />
              </AppLayout>
            </UserProtectedRoutes>
          }
        />
        <Route
          path='/home/budseedswap'
          element={
            <UserProtectedRoutes userType='consumer'>
              <AppLayout>
                <HomePage>
                  <Seeds />
                </HomePage>
              </AppLayout>
            </UserProtectedRoutes>
          }
        />
        <Route
          path='/home/budseedswap/map'
          element={
            <UserProtectedRoutes userType='consumer'>
              <AppLayout>
                <HomePage>
                  <SeedMap />
                </HomePage>
              </AppLayout>
            </UserProtectedRoutes>
          }
        />
        <Route
          path='/home/buds'
          element={
            <UserProtectedRoutes userType='consumer'>
              <AppLayout>
                <HomePage>
                  <Buds />
                </HomePage>
              </AppLayout>
            </UserProtectedRoutes>
          }
        />
        <Route
          path='/home/buds/map'
          element={
            <UserProtectedRoutes userType='consumer'>
              <AppLayout>
                <HomePage>
                  <BudsMap />
                </HomePage>
              </AppLayout>
            </UserProtectedRoutes>
          }
        />
        <Route
          path='/home/dispensaries/:radius?'
          element={
            <UserProtectedRoutes userType='consumer'>
              <AppLayout>
                <HomePage>
                  <Dispensary />
                </HomePage>
              </AppLayout>
            </UserProtectedRoutes>
          }
        />
        <Route
          path='/home/dispensaries/map'
          element={
            <UserProtectedRoutes userType='consumer'>
              <AppLayout>
                <HomePage>
                  <DispensaryMap />
                </HomePage>
              </AppLayout>
            </UserProtectedRoutes>
          }
        />
        <Route
          path='/home/cannabis/:radius?'
          element={
            <UserProtectedRoutes userType='consumer'>
              <AppLayout>
                <HomePage>
                  <Cannabis />
                </HomePage>
              </AppLayout>
            </UserProtectedRoutes>
          }
        />
        <Route
          path='/home/growDepot'
          element={
            <UserProtectedRoutes userType='consumer'>
              <AppLayout>
                <HomePage>
                  <Growdepot />
                </HomePage>
              </AppLayout>
            </UserProtectedRoutes>
          }
        />
        <Route
          path='/home/growDepot/:id'
          element={
            <UserProtectedRoutes userType='consumer'>
              <AppLayout>
                <Gowdepaotuser />
              </AppLayout>
            </UserProtectedRoutes>
          }
        />
        <Route
          path='/home/growDepot/map'
          element={
            <UserProtectedRoutes userType='consumer'>
              <AppLayout>
                <HomePage>
                  <Growdepotmap />
                </HomePage>
              </AppLayout>
            </UserProtectedRoutes>
          }
        />
        <Route
          path='/home/cannabis/map'
          element={
            <UserProtectedRoutes userType='consumer'>
              <AppLayout>
                <HomePage>
                  <CannabisMap />
                </HomePage>
              </AppLayout>
            </UserProtectedRoutes>
          }
        />
        <Route
          path='/home/headshops/:radius?'
          element={
            <UserProtectedRoutes userType='consumer'>
              <AppLayout>
                <HomePage>
                  <HeadShop />
                </HomePage>
              </AppLayout>
            </UserProtectedRoutes>
          }
        />
        <Route
          path='/home/headshops/map'
          element={
            <UserProtectedRoutes userType='consumer'>
              <AppLayout>
                <HomePage>
                  <HeadShopMap />
                </HomePage>
              </AppLayout>
            </UserProtectedRoutes>
          }
        />
        <Route
          exact
          path='/home/seedStore/:id'
          element={
            <UserProtectedRoutes userType='consumerretailer'>
              <AppLayout>
                <SeedUserProfile />
              </AppLayout>
            </UserProtectedRoutes>
          }
        />
        <Route
          path='/home/dispensary/:id'
          element={
            <UserProtectedRoutes userType='consumerretailer'>
              <AppLayout>
                <DispensaryProfileDetail />
              </AppLayout>
            </UserProtectedRoutes>
          }
        />
        <Route
          path='/home/cannabisLounge/:id'
          element={
            <UserProtectedRoutes userType='consumerretailer'>
              <AppLayout>
                <CannabisProfileDetail />
              </AppLayout>
            </UserProtectedRoutes>
          }
        />
        <Route
          path='/home/headShop/:id'
          element={
            <UserProtectedRoutes userType='consumerretailer'>
              <AppLayout>
                <HeadProfileDetail />
              </AppLayout>
            </UserProtectedRoutes>
          }
        />
        <Route
          path='/home/userItem/:id'
          element={
            <UserProtectedRoutes userType='consumer'>
              <AppLayout>
                <ConsumerDetailPage />
              </AppLayout>
            </UserProtectedRoutes>
          }
        />
        <Route
          path='/favourite'
          element={
            <UserProtectedRoutes userType='consumer'>
              <AppLayout>
                <FavoriteProduct />
              </AppLayout>
            </UserProtectedRoutes>
          }
        />
        <Route
          path='/profile/:id'
          element={
            <UserProtectedRoutes userType='consumer'>
              <AppLayout>
                <ProductUserProfile />
              </AppLayout>
            </UserProtectedRoutes>
          }
        />
        {windowSize[0] <= 576 ? (
          <>
            <Route
              path='/social/signup'
              element={
                <SocialAuthLayout>
                  <SocialSignUp />
                </SocialAuthLayout>
              }
            />
            <Route
              path='/social/summary'
              element={
                <UserProtectedRoutes userType='consumer'>
                  <SocialAuthLayout>
                    <SocialSummary />
                  </SocialAuthLayout>
                </UserProtectedRoutes>
              }
            />
            <Route
              path='/social/userdetail'
              element={
                <UserProtectedRoutes userType='consumer'>
                  <SocialAuthLayout>
                    <SocialUserDetail />
                  </SocialAuthLayout>
                </UserProtectedRoutes>
              }
            />
            <Route
              path='/social/userbio'
              element={
                <UserProtectedRoutes userType='consumer'>
                  <SocialAuthLayout>
                    <SocialUserBio />
                  </SocialAuthLayout>
                </UserProtectedRoutes>
              }
            />
            <Route
              path='/social/notice'
              element={
                <UserProtectedRoutes userType='consumer'>
                  <SocialAuthLayout>
                    <SocialNotice />
                  </SocialAuthLayout>
                </UserProtectedRoutes>
              }
            />
            <Route
              path='/social/subscription'
              element={
                <UserProtectedRoutes userType='consumer'>
                  <SocialAuthLayout>
                    <SocialSubscription />
                  </SocialAuthLayout>
                </UserProtectedRoutes>
              }
            />
            <Route
              path='/social/socialterms'
              element={
                <UserProtectedRoutes userType='consumer'>
                  <SocialAuthLayout>
                    <SocialTerms />
                  </SocialAuthLayout>
                </UserProtectedRoutes>
              }
            />
            <Route
              path='/social/socialpolicy'
              element={
                <UserProtectedRoutes userType='consumer'>
                  <SocialAuthLayout>
                    <SocialPolicy />
                  </SocialAuthLayout>
                </UserProtectedRoutes>
              }
            />
            <Route
              path='/social/uploadpicture'
              element={
                <UserProtectedRoutes userType='consumer'>
                  <SocialAuthLayout>
                    <SocialUploadPicture />
                  </SocialAuthLayout>
                </UserProtectedRoutes>
              }
            />
            <Route
              path='/social/profile'
              element={
                <UserProtectedRoutes userType='consumer'>
                  <SocialAuthLayout>
                    <SocialProfile />
                  </SocialAuthLayout>
                </UserProtectedRoutes>
              }
            />
            <Route
              path='/social/setting'
              element={
                <UserProtectedRoutes userType='consumer'>
                  <SocialAuthLayout>
                    <SocialSetting />
                  </SocialAuthLayout>
                </UserProtectedRoutes>
              }
            />
            <Route
              path='/social/lookingfor'
              element={
                <UserProtectedRoutes userType='consumer'>
                  <SocialAuthLayout>
                    <LookingFor />
                  </SocialAuthLayout>
                </UserProtectedRoutes>
              }
            />
            <Route
              path='/social/dashboard'
              element={
                <UserProtectedRoutes userType='consumer'>
                  <SocialLayout>
                    <SocialMatch />
                  </SocialLayout>
                </UserProtectedRoutes>
              }
            />
            <Route
              path='/social/profile/:id'
              element={
                <UserProtectedRoutes userType='consumer'>
                  <SocialLayout>
                    <MatchProfile />
                  </SocialLayout>
                </UserProtectedRoutes>
              }
            />
            <Route
              path='/social/posts'
              element={
                <UserProtectedRoutes userType='consumer'>
                  <SocialLayout>
                    <SocialPosts />
                  </SocialLayout>
                </UserProtectedRoutes>
              }
            />
            <Route
              path='/social/match'
              element={
                <UserProtectedRoutes userType='consumer'>
                  <SocialLayout>
                    <SocialMatch />
                  </SocialLayout>
                </UserProtectedRoutes>
              }
            />
          </>
        ) : (
          ''
        )}
        <Route
          path='/home/subscription'
          element={
            <ProtectedRoutes
              component={
                <AppLayout>
                  <DashboardSubscription />
                </AppLayout>
              }
            />
          }
        />
        <Route
          path='/home/subscriptiondetail'
          element={
            <ProtectedRoutes
              component={
                <AppLayout>
                  <SubscribedPlan />
                </AppLayout>
              }
            />
          }
        />
        <Route
          path='/home/cancel-subscription'
          element={
            <ProtectedRoutes
              component={
                <AppLayout>
                  <CancelSubscription />
                </AppLayout>
              }
            />
          }
        />

        <Route
          path='/myaccount'
          element={
            <ProtectedRoutes
              component={
                <AppLayout>
                  <UserProfile />
                </AppLayout>
              }
            />
          }
        />
        <Route
          path='/myaccount/edit'
          element={
            <ProtectedRoutes
              component={
                <AppLayout>
                  <EditProfile />
                </AppLayout>
              }
            />
          }
        />
        <Route
          path='/myaccount/delete'
          element={
            <ProtectedRoutes
              component={
                <AppLayout>
                  <DeleteProfile />
                </AppLayout>
              }
            />
          }
        />
        <Route path='/chat/:id?' element={<ProtectedRoutes component={<Chat />} />} />
      </Routes>
    </>
  );
};

export default NavigationRoutes;
