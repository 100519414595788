import React, { useEffect, useState } from 'react';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import Axios from '../../axios/Axios';
import { useNavigate, Link } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import CardPaymentForm from '../../Components/Social App/CardPaymentForm';
import { loadStripe } from '@stripe/stripe-js';
import FurthurCardPaymentForm from '../../Components/Social App/FurthurPlansCardPaymentForm ';
import { toast } from 'react-toastify';
import { createSubscription } from '../../Api';
import ReactCardFlip from 'react-card-flip';
import CrossBorderIcon from '../../assets/Images/CrossBorder';
import EmptyDataImage from '../../assets/Images/EmptyData';
import DeleteIcon from '../../assets/Images/Delete';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const SubscribedPlan = () => {
  const navigate = useNavigate();
  const [planDescription, setplanDescription] = useState([]);

  const submitHandler = e => {
    e.preventDefault();
    navigate('');
  };

  const [substype, setsubsType] = useState('');

  useEffect(() => {
    const currentUser = localStorage.getItem('userdata');
    let data = JSON.parse(currentUser);

    Axios.get(`${process.env.REACT_APP_API_URI}subscription/user-subscription/${data?._id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('user-token')}`
      }
    })
      .then(response1 => {
        setsubsType(response1.data.userSubscriptions);
        const url =
          data.userType === 'retailer'
            ? `${process.env.REACT_APP_API_URI}plan/${data.retailerType}`
            : `${process.env.REACT_APP_API_URI}plan/`;
        Axios.get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('user-token')}`
          }
        })
          .then(response2 => {
            let latestSubscriptionDate = null;
            let latestSubscriptionIndex = -1;

            response1.data.userSubscriptions.forEach((subscription, index) => {
              const subscriptionDate = new Date(subscription.createdAt);
              if (!latestSubscriptionDate || subscriptionDate > latestSubscriptionDate) {
                latestSubscriptionDate = subscriptionDate;
                latestSubscriptionIndex = index;
              }
            });

            let plans = response2.data.plans.map(data => {
              if (data._id === response1.data.userSubscriptions[latestSubscriptionIndex].planId) {
                if (data?._id) {
                  setplanDescription(data);
                  return data;
                }
              }
            });
            plans = plans.filter(data => data !== undefined);

            if (plans?.length === 0 || !plans) {
              navigate('/home/subscription');
            }
          })
          .catch(error => {
            console.log(error?.response?.data);
          });
      })
      .catch(error => {
        console.log(error?.response?.data);
      });
  }, []);

  return (
    <>
      <div className='all-product-section dashboard-subs'>
        <div className='container mx-auto'>
          <div className='d-flex align-items-center justify-content-between'>
            <h2 className='allproduct-heading mx-4'>Subscribed Plan</h2>
            <button
              className='green-btn w-max-content px-3 me-4'
              onClick={() => navigate('/home/subscription')}
              type='button'
            >
              Upgrade Now
            </button>
          </div>
          {substype !== '' && substype !== undefined ? (
            <form onSubmit={e => submitHandler(e)} className='ms-12 me-12 mt-5'>
              <div className='row m-0 gap-md-0 gap-4'>
                <div className='col-md-6'>
                  <h3 className='font-weight-700 font-24 mb-4'>Plan Offering:</h3>
                </div>
                <div className='col-md-6 d-md-block d-none'>
                  <h3 className='font-weight-700 font-24 mb-4'>Plans Detail:</h3>
                </div>
              </div>
              <div className='row m-0 gap-md-0 gap-4 ps-12 me-12'>
                <div className='col-md-6   my-1'>
                  <div className=' rounded-4 p-4 d-flex flex-column h-100 border border-1 border-black bg-dark-brown text-white'>
                    <p className='font-weight-700 font-18-social'>Grow and Share</p>
                    <div className='auth-subscription mt-0'>
                      <ul>
                        {(planDescription.description || []).map((data, index) => {
                          return (
                            <li key={index}>
                              <h4 className='font-16-social text-white font-weight-400 line-height-20px'>{data}</h4>
                            </li>
                          );
                        })}
                      </ul>
                      {/* <div className="auth-input bg-white h-auto mt-4 text-dark p-3">
                      <div className="d-flex flex-column">
                        <div className="d-flex justify-content-between text-dark gap-3 align-items-center border-bottom border-grey pb-3 flex-wrap">
                          <span className="font-16-social">
                            Subscription Type
                          </span>
                          <h4 className="font-18-social">
                            {planDescription.name}
                          </h4>
                        </div>
                        <div className="d-flex justify-content-between text-dark gap-3 align-items-center pt-3">
                          <span className="font-16-social">Total</span>
                          <h4 className="font-18-social">
                            ${planDescription.price}
                          </h4>
                        </div>
                      </div>
                    </div> */}
                    </div>
                  </div>
                </div>
                <div className='col-md-6 my-1'>
                  <div className=' rounded-4 p-4 d-flex flex-column border border-1 border-black bg-dark-brown text-white'>
                    <p className='font-weight-700 font-18-social'>Grow and Share</p>
                    <div className='auth-subscription mt-0 bg-dark-brown text-white'>
                      <div className='bg-dark-brown text-white h-auto mt-4'>
                        <div className='d-flex flex-column'>
                          <div className='d-flex justify-content-between text-white gap-3 align-items-center border-bottom border-grey pb-3 flex-wrap'>
                            <span className='font-16-social'>Subscription Type</span>
                            <h4 className='font-18-social'>{planDescription.name}</h4>
                          </div>
                          <div className='d-flex justify-content-between text-white gap-3 align-items-center pt-3'>
                            <span className='font-16-social'>Cost</span>
                            <h4 className='font-18-social'>${planDescription.price}</h4>
                          </div>
                          {/* <Link
                            to={'/home/cancel-subscription'}
                            className='red-btn w-max-content px-3 d-flex align-items-center justify-content-between font-18 py-sm-3 user mt-4'
                          >
                            <span className='text-white'>Cancel Subscription</span>
                          </Link> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          ) : (
            <div className='d-flex justify-content-center w-100 mt-5'>
              <EmptyDataImage />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SubscribedPlan;
