import React, { useState } from 'react';
import UploadIcon from '../../assets/Images/Upload';
import { useNavigate } from 'react-router-dom';
import Add1 from '../../assets/Images/match/Add1';
import { useEffect } from 'react';
import Axios from '../../axios/Axios';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { ButtonToolbar, OverlayTrigger, Tooltip } from 'react-bootstrap';

const AddConsumerProduct = props => {
  const { setApiResponse, isUpdateModel, updateModelData, setIsUpdating } = props;
  const [selectedImages, setSelectedImages] = useState([]);
  const [file, setFile] = useState(null);
  const [type, setType] = useState('Grams');
  const [allStrains, setAllStrains] = useState();
  const [productForms, setProductForms] = useState([
    {
      strainType: '',
      // grownType: "",
      photo: null,
      strainName: '',
      description: '',
      quantity: '',
      thc: '',
      cbd: ''
    }
  ]);
  const navigate = useNavigate();

  const [isFormValid, setIsFormValid] = useState(false);

  const allStrainsData = (allStrains || []).map(strain => ({
    value: strain?._id,
    label: strain?.strainName || strain?.productName || strain?.event || strain?.name
  }));
  const handleChange = event => {
    setType(event.target.value);
  };
  useEffect(() => {
    GetAllStrains();
  }, []);

  const GetAllStrains = async () => {
    try {
      const fetchData = await Axios.get(`${process.env.REACT_APP_API_URI}users/getAllStrains`);
      setAllStrains(fetchData.data.result);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      console.log(error);
    }
  };

  const [value, setValue] = useState('');
  const StrainTypeHandler = (data, index) => {
    const matchedObject = allStrains.find(obj => obj?._id === data.value);
    const updatedProductForms = [...productForms];

    updatedProductForms[index] = {
      ...updatedProductForms[index],
      product: data,
      strainName:
        matchedObject?.strainName || matchedObject?.productName || matchedObject?.event || matchedObject?.name,
      description: matchedObject?.description,
      strainType: matchedObject?.postStrain ? matchedObject?.postStrain : ''
    };
    setProductForms(updatedProductForms);
  };

  useEffect(() => {
    if (!isUpdateModel) {
      const savedData = JSON.parse(localStorage.getItem('signupData'));
      if (savedData?.length > 0 && Array.isArray(savedData)) {
        setProductForms(savedData);
      } else {
        setProductForms([
          {
            strainType: '',
            // grownType: "",
            photo: null,
            strainName: '',
            description: '',
            quantity: '',
            thc: '',
            cbd: ''
          }
        ]);
      }
    } else {
      setFile(updateModelData?.photo);
      setProductForms([
        {
          strainType: updateModelData?.strainType,
          // grownType: "",
          photo: updateModelData?.photo,
          strainName: updateModelData?.strainName,
          description: updateModelData?.description,
          quantity: updateModelData?.quantity,
          thc: updateModelData?.thc,
          cbd: updateModelData?.cbd
        }
      ]);
    }
    setType(updateModelData?.quantityType);
  }, [isUpdateModel]);
  useEffect(() => {
    localStorage.setItem('signupData', JSON.stringify(productForms));
  }, [productForms]);

  useEffect(() => {
    const areAllFormsValid = productForms.every(form => {
      const { strainType, strainName, quantity, description, thc, cbd } = form;
      return strainType && strainName && quantity && description && thc && cbd;
    });

    setIsFormValid(areAllFormsValid);
  }, [productForms]);
  const formHandler = (e, index) => {
    const { name, value } = e.target;
    const updatedProductForms = [...productForms];
    updatedProductForms[index] = {
      ...updatedProductForms[index],
      [name]: value
    };
    setProductForms(updatedProductForms);
  };

  const attachFile = (e, index) => {
    if (e.target.files) {
      const updatedProductForms = [...productForms];
      updatedProductForms[index] = {
        ...updatedProductForms[index],
        photo: Array.from(e.target.files)
      };
      const files = Array.from(e.target.files);
      setProductForms(updatedProductForms);
      setSelectedImages(files);
      setFile(e.target.files[0].name);
    }
  };
  const clearForm = () => {
    setProductForms([
      ...productForms,
      {
        strainType: '',
        // grownType: "",
        photo: null,
        strainName: '',
        description: '',
        quantity: '',
        thc: '',
        cbd: ''
      }
    ]);
    setFile(null);
  };
  const removeForm = indexToRemove => {
    const updatedProductForms = productForms.filter((_, index) => index !== indexToRemove);
    setProductForms(updatedProductForms);
  };

  const submitHandler = async e => {
    e.preventDefault();
    const currentUser = localStorage.getItem('userdata');
    const parsedUser = JSON.parse(currentUser);
    const userId = parsedUser._id;
    let data = new FormData();
    productForms.forEach((mapData, index) => {
      data.append('userId', userId);
      data.append('strainType', mapData.strainType);
      data.append('quantity', mapData.quantity);
      data.append('quantityType', type || 'Grams');
      // data.append("grownType", mapData.grownType);
      data.append('strainName', mapData.strainName);
      data.append('description', mapData.description);
      data.append('thc', mapData.thc);
      data.append('cbd', mapData.cbd);
      if (Array.isArray(mapData.photo)) {
        mapData.photo.forEach(file => data.append(`photo-${index}`, file));
      } else {
        data.append(`photo-${index}`, mapData.photo);
      }
    });
    await PostResponse(data);
    clearForm();
    //addNewhandler();
    setProductForms([
      {
        strainType: '',
        // grownType: "",
        photo: null,
        strainName: '',
        description: '',
        quantity: ''
      }
    ]);
    localStorage.removeItem('signupData');
    navigate('/myaccount');
  };

  const goBack = () => {
    navigate(-1);
  };

  const PostResponseUrl = `${process.env.REACT_APP_API_URI}userItem`;
  const PatchResponseUrl = `${process.env.REACT_APP_API_URI}userItem/${updateModelData?._id}`;

  const PostResponse = async newArray => {
    try {
      let isStrainAdded = false;
      if (isUpdateModel) {
        await Axios.patch(PatchResponseUrl, newArray);
      } else {
        console.log('else');
        await Axios.post(PostResponseUrl, newArray);
        isStrainAdded = true;
      }
      toast.success('Strain Added Successfully');
      navigate('/myaccount', { state: { isNewStrainPosted: isStrainAdded } });
      setApiResponse(true);
    } catch (error) {
      toast.error(error.response?.data?.message);
      console.log(error);
    }
  };

  return (
    <>
      {!isUpdateModel ? (
        <h2 className='font-32 font-weight-600 bordered-heading mb-2 mx-4'>
          Strain Exchange: Swap Strains, Cut Costs and Curate Your Collection!
        </h2>
      ) : (
        <h2 className='font-32 font-weight-600 bordered-heading mb-2 mx-4'>Edit Your Strain</h2>
      )}
      <form onSubmit={e => submitHandler(e)} className='overflow-auto px-4'>
        <div className='addMore-form'>
          {productForms.map((form, index) => (
            <div key={index} className='addMore-form-border pt-5'>
              {index !== 0 && (
                <div className='d-flex justify-content-end align-items-center mb-2'>
                  <button
                    type='button'
                    onClick={() => removeForm(index)}
                    className='red-btn-outlines px-4 w-max-content'
                  >
                    Remove Form
                  </button>
                </div>
              )}
              {!isUpdateModel && (
                <div className='d-flex flex-md-row flex-column align-items-center gap-4 justify-content-between mb-4'>
                  <div className='form-control h-auto p-0 bg-transparent border-0'>
                    <label className=' mb-2 font-weight-600 font-18-100'>Select Strain</label>
                    <Select
                      onChange={e => StrainTypeHandler(e, index)}
                      value={form.product || ''}
                      className='searchable-select'
                      classNamePrefix='select'
                      isDisabled={false}
                      isLoading={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={true}
                      name='color'
                      options={allStrainsData}
                    />
                  </div>
                </div>
              )}

              <div className='d-flex flex-md-row flex-column align-items-start gap-4 justify-content-between mb-4'>
                <div className='form-control h-auto p-0 bg-transparent border-0'>
                  <label className=' mb-2 font-weight-600 font-18-100'>Name</label>
                  <input
                    onChange={e => formHandler(e, index)}
                    type='text'
                    className='auth-input bg-white'
                    placeholder='Enter Name'
                    required
                    value={form.strainName || ''}
                    name='strainName'
                  />
                  <span className='font-12 '>If you can’t find your strain in the dropdown menu, write it here</span>
                </div>
                <div className='form-control h-auto p-0 bg-transparent border-0'>
                  <label className=' mb-2 font-weight-600 font-18-100'>Which Strain type do you have?</label>
                  <select
                    className='auth-input bg-white'
                    required
                    name='strainType'
                    onChange={e => formHandler(e, index)}
                    value={form.strainType || ''}
                  >
                    <option value={''}>Strain Type</option>
                    <option value={'Sativa'}>Sativa</option>
                    <option value={'Indica'}>Indica</option>
                    <option value={'Hybrid'}>Hybrid</option>
                    <option value={'CBD'}>CBD</option>
                  </select>
                </div>
              </div>
              <div className='d-flex flex-md-row flex-column align-items-end gap-4 justify-content-between mb-4'>
                <div className='form-control h-auto p-0 bg-transparent border-0'>
                  <label className=' mb-2 font-weight-600 font-18-100 d-flex gap-2 align-items-end justify-content-between flex-wrap'>
                    Choose Quantity for Swapping
                    <div className='btn-group btn-group-toggle height-42 btn-group-dash' data-toggle='buttons'>
                      <label className='btn font-14 bg-grey active d-flex align-items-center py-3 px-2'>
                        <input
                          type='radio'
                          name='options'
                          id='Grams'
                          autoComplete='off'
                          readOnly
                          checked={type === 'Grams'}
                          onChange={handleChange}
                          value='Grams'
                        />
                        <span className='pl-2'>Grams</span>
                      </label>
                      <label className='btn font-14 bg-grey d-flex align-items-center px-2'>
                        <input
                          type='radio'
                          name='options'
                          id='Seeds'
                          value='Seeds'
                          autoComplete='off'
                          checked={type === 'Seeds'}
                          onChange={handleChange}
                        />
                        <span className='pl-2'>Seeds</span>
                      </label>
                    </div>
                  </label>
                  <select
                    className='auth-input bg-white'
                    required
                    name='quantity'
                    onChange={e => formHandler(e, index)}
                    value={form.quantity || ''}
                  >
                    <option value={''}>- Quantity available for swap -</option>
                    <option value={type === 'Seeds' ? '1-4' : '1-7'}>
                      {type === 'Seeds' ? '1-4 Seeds' : '1-7 Grams'}
                    </option>
                    <option value={type === 'Seeds' ? '5-10' : '7-14'}>
                      {type === 'Seeds' ? '5-10 Seeds' : '7-14 Grams'}
                    </option>
                    <option value={type === 'Seeds' ? '11-15' : '14-30'}>
                      {type === 'Seeds' ? '11-15 Seeds' : '14-30 Grams'}
                    </option>
                    {type === 'Seeds' && <option value={'16-20'}>{'16-20 Seeds'}</option>}
                  </select>
                </div>
                <div className='form-control h-auto p-0 bg-transparent border-0'>
                  <label className='text-black mt-md-4 mb-2 font-weight-600 font-18-100'>THC %</label>
                  <input
                    onChange={e => {
                      if (e.target.value <= 100 && e.target.value >= 0) {
                        formHandler(e, index);
                      }
                    }}
                    type='number'
                    className='auth-inputs'
                    placeholder='% THC'
                    required
                    value={form.thc}
                    name='thc'
                    min='0'
                    max='100'
                    step='1'
                    inputmode='numeric'
                  />
                </div>
                <div className='form-control h-auto p-0 bg-transparent border-0'>
                  <label className='text-black mt-md-4 mb-2 font-weight-600 font-18-100'>CBD %</label>
                  <input
                    onChange={e => {
                      if (e.target.value <= 100 && e.target.value >= 0) {
                        formHandler(e, index);
                      }
                    }}
                    type='number'
                    className='auth-inputs'
                    placeholder='% CBD'
                    required
                    value={form.cbd}
                    name='cbd'
                    min='0'
                    max='100'
                    step='1'
                    inputmode='numeric'
                  />
                </div>

                {/* <div className="form-control h-auto p-0 bg-transparent border-0 ">
                  <label className="mt-md-4 mb-2 font-weight-600 font-18-100">
                    Grown or Purchased from Dispensary?
                  </label>
                  <select
                    className="auth-input bg-white"
                    required
                    name="grownType"
                    onChange={(e) => formHandler(e, index)}
                    value={form.grownType || ""}
                  >
                    <option value={""}>- Select Option -</option>
                    <option value={"Dispensary"}>
                      Purchased from Dispensary
                    </option>
                    <option value={"Grown"}>Grown</option>
                  </select>
                </div> */}
              </div>

              <div className='form-control h-auto p-0 bg-transparent border-0 mb-4'>
                <label className=' mb-2 font-weight-600 font-18-100'>Description</label>
                <textarea
                  onChange={e => formHandler(e, index)}
                  className='auth-input-textarea bg-white'
                  placeholder='Enter description here...'
                  required
                  name='description'
                  value={form.description || ''}
                />
              </div>
              <div className='d-flex flex-md-row flex-column align-items-end gap-4 justify-content-between mb-4'>
                <div className='form-control h-auto p-0 bg-transparent border-0'>
                  <label className=' mb-2 font-weight-600 font-18-100'>Upload Images</label>
                  <label className='upload-file cr-p w-100 '>
                    <input
                      type='file'
                      className='d-none'
                      accept='.jpg, .jpeg, .png'
                      onChange={e => attachFile(e, index)}
                    />
                    <div className='d-flex justify-content-center align-items-center h-100 w-100 gap-2'>
                      <UploadIcon />
                      <p className='font-16 font-weight-500'>
                        {file === null ? 'Choose File / Drag & Drop Here' : file}
                      </p>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          ))}
        </div>
        {!isUpdateModel && (
          <div className='d-flex flex-md-row flex-column align-items-center gap-4 justify-content-between mb-4'>
            {productForms?.length < 10 ? (
              <button
                className='add-mores bg-transparent border-white text-black gap-2'
                type='button'
                onClick={() => clearForm()}
                disabled={productForms?.length >= 10}
              >
                <Add1 />
                Add More Products
              </button>
            ) : (
              <ButtonToolbar className='add-mores bg-transparent border-white text-black p-0' type='button'>
                <OverlayTrigger placement='top' overlay={tooltip}>
                  <div className='d-flex align-items-center gap-2 w-100 h-100 justify-content-center'>
                    <Add1 />
                    Add More Products
                  </div>
                </OverlayTrigger>
              </ButtonToolbar>
            )}
          </div>
        )}
      </form>
      <div className='d-flex flex-sm-row flex-column align-items-center gap-4 justify-content-end  mt-3 px-4'>
        <button
          className='green-btn-outlines custom-w min-width-208'
          type='button'
          data-bs-dismiss='modal'
          onClick={() => {
            setProductForms([
              {
                type: '',
                cost: '',
                productName: '',
                photo: null,
                description: '',
                cbd: '',
                thc: ''
              }
            ]);
            if (setIsUpdating) setIsUpdating(false);
          }}
        >
          Cancel
        </button>
        <button
          className={`green-btn custom-w min-width-208 height-56-important ${
            isFormValid ? 'enabled-button' : 'disabled-button'
          }`}
          type='submit'
          data-bs-dismiss='modal'
          disabled={!isFormValid}
          onClick={e => submitHandler(e)}
        >
          {isUpdateModel ? 'Update' : 'Post'}
        </button>
      </div>
    </>
  );
};

export default AddConsumerProduct;
const tooltip = <Tooltip id='tooltip'>You can't add more than 10 products at a time.</Tooltip>;
