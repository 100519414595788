import Axios from '../../axios/Axios';
import React, { useEffect, useState } from 'react';
import EmptyDataImage from '../../assets/Images/EmptyData';
import CrossBorderIcon from '../../assets/Images/CrossBorder';
import { toast } from 'react-toastify';

const CancelSubscription = () => {
  const [plansData, setPlansData] = useState([]);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(null);

  useEffect(() => {
    fetchUserSubscriptions();
  }, []);

  const fetchUserSubscriptions = async () => {
    try {
      const currentUser = localStorage.getItem('userdata');
      let data = JSON.parse(currentUser);

      const userSubscriptions = await Axios.get(
        `${process.env.REACT_APP_API_URI}subscription/user-subscription/${data?._id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('user-token')}`
          }
        }
      );

      const url =
        data.userType === 'retailer'
          ? `${process.env.REACT_APP_API_URI}plan/${data.retailerType}`
          : `${process.env.REACT_APP_API_URI}plan/`;

      const plansResponse = await Axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('user-token')}`
        }
      });

      let subscribedPlanIds = userSubscriptions?.data?.userSubscriptions?.map(data => data?.planId);

      let plansData = plansResponse?.data?.plans
        ?.filter(plan => subscribedPlanIds?.includes(plan?._id))
        ?.map(plan => ({
          ...plan,
          subscription: userSubscriptions?.data?.userSubscriptions?.find(
            subscription => subscription?.planId === plan._id
          )
        }));

      setPlansData(plansData);
    } catch (error) {
      console.log(error?.response?.data || error);
    }
  };

  const handleCancelSubscription = async () => {
    if (!selectedSubscriptionId) {
      toast.error('Plan Not Selected');
      return;
    }
    try {
      const response = await Axios.delete(
        `${process.env.REACT_APP_API_URI}subscription/cancel-subscription/${selectedSubscriptionId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('user-token')}`
          }
        }
      );
      if (response?.data) {
        toast.success(response?.data?.message);
        fetchUserSubscriptions();
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
    setSelectedSubscriptionId(null);
  };
  return (
    <div className='all-product-section dashboard-subs'>
      <div className='container mx-auto'>
        <h2 className='allproduct-heading mx-4'>Subscribed Plans</h2>
        <div className='row  gap-4 mt-3'>
          {plansData?.length ? (
            plansData?.map((plan, index) => (
              <div
                key={index}
                className='col-md-5 rounded-4 p-4 d-flex flex-column h-100 border border-1 border-black bg-dark-brown text-white'
              >
                <div className='d-flex align-items-center justify-content-between'>
                  <p className='font-weight-700 font-18-social'>Grow and Share</p>
                  {plan?.subscription?.canceledAtPeriodEnd && (
                    <p
                      className='px-2 py-1 text-white'
                      style={{ background: '#be3f3f', fontSize: '14px', borderRadius: '12px', fontWeight: '500' }}
                    >
                      Cancelled
                    </p>
                  )}
                </div>
                <div className='auth-subscription mt-0'>
                  <ul>
                    {(plan?.description || []).map((data, index) => {
                      return (
                        <li key={index}>
                          <h4 className='font-16-social text-white font-weight-400 line-height-20px'>{data}</h4>
                        </li>
                      );
                    })}
                  </ul>
                  <div className='auth-input h-auto mt-4 text-dark p-3'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex justify-content-between text-dark gap-3 align-items-center border-bottom border-grey pb-3 flex-wrap'>
                        <span className='font-16-social text-white'>Subscription Type</span>
                        <h4 className='font-18-social text-white'>{plan?.name}</h4>
                      </div>
                      <div className='d-flex justify-content-between text-dark gap-3 align-items-center pt-3'>
                        <span className='font-16-social text-white'>Total</span>
                        <h4 className='font-18-social text-white'>${plan?.price}</h4>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex align-items-center justify-content-center '>
                    <button
                      data-bs-toggle='modal'
                      data-bs-target='#cancelsubscription'
                      disabled={plan?.subscription?.status == 'canceled'}
                      className='red-btn w-max-content px-3 mt-3 '
                      onClick={() => setSelectedSubscriptionId(plan?.subscription?.subscriptionId)}
                    >
                      Cancel Subscription
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className='d-flex justify-content-center w-100 mt-5'>
              <EmptyDataImage />
            </div>
          )}
        </div>
        <div
          className='modal fade'
          id={`cancelsubscription`}
          data-bs-backdrop='static'
          data-bs-keyboard='false'
          tabIndex={-1}
          aria-labelledby='staticBackdrop11Label'
          aria-hidden='true'
        >
          <div className='modal-dialog custom-model w-max-content modal-dialog-centered mx-auto user-model'>
            <div className='modal-content justify-content-center p-4'>
              <div className='d-flex justify-content-end'>
                <span className='cr-p' data-bs-dismiss='modal' onClick={() => setSelectedSubscriptionId(null)}>
                  <CrossBorderIcon />
                </span>
              </div>
              <div className='d-flex flex-column align-items-center gap-5 justify-content-center mb-5 mt-4 pt-2'>
                <p className='font-32 font-weight-800 text-center'>
                  Are you sure you want to cancel your subscription?
                </p>
              </div>
              <div className='d-flex flex-sm-row flex-column align-items-center gap-4 justify-content-between w-100'>
                <button
                  className='green-btn-outline text-primary-green w-100'
                  data-bs-dismiss='modal'
                  onClick={() => setSelectedSubscriptionId(null)}
                >
                  Close
                </button>
                <button className='red-btn w-100' data-bs-dismiss='modal' onClick={handleCancelSubscription}>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelSubscription;
